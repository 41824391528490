import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  .background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow:hidden;
    video {
      position: absolute;
      z-index: 0;
      background-size: 100% 100%;
      top: 0px;
      left: 0px; /* fixed to left. Replace it by right if you want.*/
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .container-login {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border-radius: 8px;
    width: 400px;
    height: 500px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    animation: mymove 3s ease forwards;
    animation-iteration-count: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    h1 {
      font-size: 2rem;
      color: black;
      margin-bottom: 20px;
    }
    Form {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      p {
        width: 80%;
        color: black;
        font-size: 1.6rem;
        font-weight: 600;
        a {
          color: rgba(0, 0, 0, 0.5);
          :hover {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      Input {
        border-radius: 8px;
        height: 45px;
        width: 80%;
        margin-bottom: 5px;
        padding: 0 10px;
      }
      .check {
        width: 80%;
        display: flex;
        align-items: center;
        Input {
          width: 20px;
          height: 20px;
        }
        label {
          font-size: 1.3rem;
          margin-left: 3px;
        }
      }
      button {
        border-radius: 8px;
        height: 45px;
        width: 80%;
        margin: 15px 0;
        background-color: #33d525;
        color: white;
        font-weight: 600;
        :hover {
          cursor: pointer;
          background-color: ${darken(0.2, "#33d525")};
        }
      }
    }
  }

  @keyframes mymove {
    from {
      transform: translateY(200px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: 450px) {
    .container-login {
      margin: 0 0 0 0;
      width: 100%;
      height: 70%;
    }
  }
`;
