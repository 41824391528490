import React, { useState, useEffect } from 'react';
import { ImageUploader } from '../Image_uploader';
import api from '../../services/api';
import { MaskMoney } from '../../util/Validators/MaskMoney';

const EventForm = ({ event, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    date: '',
    date_end: '',
    description: '',
    free_entry: false,
    limit_peoples: 0,
    value: '',
    locale: '',
    need_confirm_presence: false,
    avatar_event: null,
    avatar_id: null,
  });

  const [avatarBlob, setAvatarBlob] = useState(null); // Para armazenar o blob da imagem

  useEffect(() => {
    if (event) {
      setFormData({
        name: event.name || '',
        date: event.date || '',
        date_end: event.date_end || '',
        description: event.description || '',
        free_entry: event.free_entry || false,
        limit_peoples: event.limit_peoples || 0,
        locale: event.locale || '',
        value: event.value || 0,
        need_confirm_presence: event.need_confirm_presence || false,
        avatar_event: event.avatar_event || null,
        avatar_id: event.avatar_id || null,
      });
    }
  }, [event]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageUpload = (file) => {
    setAvatarBlob(file); // Recebe o blob da imagem
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Adiciona o blob da imagem, ou outro arquivo no formData
      if (avatarBlob) {
        formDataToSend.append('file', avatarBlob);
        const { data } = await api.post('/files', formDataToSend);
      
        // Atualiza o formData com o ID retornado do upload da imagem
        setFormData((prev) => ({
          ...prev,
          avatar_id: data.id,
        }));
  
        return onSubmit({ ...formData, avatar_id: data.id });
      }

      return onSubmit({ ...formData });

    } catch (error) {
      console.error('Erro ao salvar a imagem:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white shadow-md rounded-lg w-full h-full">
      {/* Outros campos do formulário */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Nome do Evento:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Data do Evento:</label>
        <input
          type="datetime-local"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Encerramento do Evento:</label>
        <input
          type="datetime-local"
          name="date_end"
          value={formData.date_end}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Descrição:</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="4"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Entrada Gratuita:</label>
        <input
          type="checkbox"
          name="free_entry"
          checked={formData.free_entry}
          onChange={handleChange}
          className="form-checkbox"
        />
      </div>
      {
        !formData.free_entry && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Valor da Entrada:</label>
            <input
              type="text"
              name="value"
              value={MaskMoney(formData.value)}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )
      }

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Limite de Pessoas:</label>
        <input
          type="number"
          name="limit_peoples"
          value={formData.limit_peoples}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Local:</label>
        <input
          type="text"
          name="locale"
          value={formData.locale}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Confirmar Presença:</label>
        <input
          type="checkbox"
          name="need_confirm_presence"
          checked={formData.need_confirm_presence}
          onChange={handleChange}
          className="form-checkbox"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Avatar do Evento:</label>
        <ImageUploader onImageUpload={handleImageUpload} url={event?.avatar_event?.url ? event.avatar_event.url : ""} />
      </div>

      {/* Botões */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
        >
          Salvar
        </button>
      </div>
    </form>
  );
};

export { EventForm };
