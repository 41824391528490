/* eslint-disable import/no-webpack-loader-syntax */
import { Container, Pin, PopupContainer } from "./styles";
import { BiChurch } from "react-icons/bi";
import { VscActivateBreakpoints } from "react-icons/vsc";

import Map, { Marker, Popup } from "react-map-gl";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../services/api";
import mapboxgl from "mapbox-gl";
import { toast } from "react-toastify";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function Locale() {
  const [popupInfo, setPopupInfo] = useState(null);
  const [cells, setCells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    getInfo();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  async function getInfo() {
    try {
      setLoading(true);
      let { data: cells } = await api.get("/cells");

      setCells(cells);
      setLoading(false);
    } catch (error) {
      toast.error("Falha ao carregar célular");
    }
  }

  const TOKEN = process.env.REACT_APP_TOKEN_MAP_BOX;

  return (
    <Container>
      {!loading ? (
        <Map
          width={screenSize.dynamicWidth}
          height={screenSize.dynamicHeight}
          initialViewState={{
            latitude: -21.12641,
            longitude: -51.1051421,
            zoom: 18,
          }}
          mapboxAccessToken={TOKEN}
          mapStyle="mapbox://styles/mapbox/dark-v9"
        >
          {cells.map((cell, index) => (
            <Marker
              key={index + 1}
              latitude={Number(cell.latitude)}
              longitude={Number(cell.longitude)}
              anchor={"center"}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo({
                  name: cell.name,
                  day: cell.day,
                  hour_started: cell.hour_started,
                  hour_finish: cell.hour_finish,
                  latitude: Number(cell.latitude),
                  longitude: Number(cell.longitude),
                });
              }}
            >
              <Pin>
                <VscActivateBreakpoints color="white" size={24} />
              </Pin>
            </Marker>
          ))}
          <Marker
            key={0}
            latitude={-21.12641}
            longitude={-51.1051421}
            anchor={"center"}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setPopupInfo({
                name: "Primeira Igreja Batista",
                church: true,
                latitude: -21.12641,
                longitude: -51.1051421,
              });
            }}
          >
            <Pin>
              <BiChurch color="white" size={36} />
            </Pin>
          </Marker>
          {popupInfo && (
            <Popup
              anchor="top"
              offset={30}
              closeButton={false}
              longitude={Number(popupInfo.longitude)}
              latitude={Number(popupInfo.latitude)}
              onClose={() => setPopupInfo(null)}
            >
              <PopupContainer>
                {popupInfo.church ? (
                  <div className="churchDiv">
                    <p>{popupInfo.name}</p>
                    <a
                      style={{ color: "black" }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir//${popupInfo.latitude},${popupInfo.longitude}`}
                    >
                      <button>Como chegar</button>
                    </a>
                  </div>
                ) : (
                  <div className="cellInfo">
                    <h4>{popupInfo.name}</h4>
                    <p>Dia:{popupInfo.day}</p>
                    <p>
                      Horário:{popupInfo.hour_started} - {popupInfo.hour_finish}
                    </p>
                    <a
                      style={{ color: "black" }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir//${popupInfo.latitude},${popupInfo.longitude}`}
                    >
                      <button>Como chegar</button>
                    </a>
                  </div>
                )}
              </PopupContainer>
            </Popup>
          )}
        </Map>
      ) : (
        <>
          <h4>carregando...</h4>
        </>
      )}
    </Container>
  );
}
export default Locale;
