import React from "react";

import { Container } from "./styles";
import Pix from "../../assets/pix.jpeg";
import Ofertas from "../../assets/ofertas.jpeg";
function Offers() {
  return (
    <Container>
      <div className="wrapper">
        <img src={Ofertas} alt="ofertas" />
        <img src={Pix} alt="pix" />
      </div>
    </Container>
  );
}

export default Offers;
