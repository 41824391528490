import React from "react";

import { Container } from "./styles";

function page404() {
  return (
    <Container>
      <div className="wrapper">
        <h1>Erro 404</h1>
        <p>Esta página não existe</p>
      </div>
    </Container>
  );
}

export default page404;
