import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteWrapper from "./RouteWrapper"; // Importa o wrapper de rotas
import Believer from "../pages/Believer";
import Events from "../pages/Events";
import Home from "../pages/Home";
import InfoEvents from "../pages/InfoEvents";
import Login from "../pages/Login";
import PrintQR from "../pages/PrintQR";
import Register from "../pages/Register";
import Ticket from "../pages/Tickets";
import AlbumImages from "../pages/AlbumImages";
import Albums from "../pages/Albums";
import CenterCells from "../pages/CentralPlenitude/Cells";
import Central from "../pages/CentralPlenitude/Central";
import CenterEvents from "../pages/CentralPlenitude/Events";
import CenterBasket from "../pages/CentralPlenitude/Love_basket";
import Members from "../pages/CentralPlenitude/Members";
import Courses from "../pages/Courses";
import Offers from "../pages/Offers";
import Page404 from "../pages/page404"; // Nome atualizado para Page404
import PolicyPrivace from "../pages/PolicyPrivace";
import RegisterCourse from "../pages/CentralPlenitude/RegisterCourse";
import DescriptionCourse from "../pages/Courses/components/Description";
import MyCourses from "../pages/Courses/components/MyCourses";
import PlayCourse from "../pages/Courses/components/PlayCourse";
import Locale from "../pages/Locale";
import Profile from "../pages/Profile";

const RoutesComponent = () => {
  return (
    <Routes>
      {/* Rotas com Header */}
      <Route element={<RouteWrapper isHeader />}>
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Local" element={<Locale />} />
        <Route path="/InfoEvents/:id" element={<InfoEvents />} />
        <Route path="/Believer" element={<Believer />} />
        <Route path="/Offers" element={<Offers />} />
        <Route path="/PolicyPrivace" element={<PolicyPrivace />} />
        <Route path="/Galeria" element={<Albums />} />
        <Route path="/Galeria/:name" element={<AlbumImages />} />
      </Route>

      {/* Rotas Privadas */}
      <Route element={<RouteWrapper isPrivate isHeader />}>
        <Route path="/Ticket" element={<Ticket />} />
        <Route path="/PrintQR" element={<PrintQR />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Cursos" element={<Courses />} />
        <Route path="/MeusCursos" element={<MyCourses />} />
        <Route path="/Aulas/:id" element={<PlayCourse />} />
        <Route path="/Descricao/:id" element={<DescriptionCourse />} />
      </Route>

      {/* Rotas Privadas com Layout Central */}
      <Route element={<RouteWrapper isPrivate isCentral />}>
        <Route path="/Central" element={<Central />} />
        <Route path="/Members" element={<Members />} />
        <Route path="/Celulas" element={<CenterCells />} />
        <Route path="/CadastroCursos" element={<RegisterCourse />} />
        <Route path="/CenterEvents" element={<CenterEvents />} />
        <Route path="/CenterBasket" element={<CenterBasket />} />
      </Route>

      {/* Rota de Página não encontrada */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export {RoutesComponent};
