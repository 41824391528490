/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Modal, Select } from "@mui/material";
import {
  Add,
  ArrowLeft,
  ArrowRight,
  CameraAlt,
  Close,
  Edit,
  SaveAs,
  Visibility,
  PictureAsPdf,
  ExpandMore,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import PT from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import CheckboxGroup from "react-checkbox-group";
import { toast } from "react-toastify";
import AvatarInput from "../../../components/AvatarInput";
import api from "../../../services/api";
import MaskCep from "../../../util/Validators/MaskCep";
import {
  MaskCelNumber,
  maskPhoneNumber,
} from "../../../util/Validators/MaskPhone";
import { RemoveMask } from "../../../util/Validators/RemoveMask";
import { ValidateEmail } from "../../../util/Validators/ValidateEmail";
import {
  Container,
  ContentModal,
  ContentModalView,
  FormContainer,
  GridContaint,
  GroupButtons,
  GroupCheckbox,
  ImageContent,
  Img,
  ModalStyle,
  RadioGroup,
} from "./styles";
import Paper from "@mui/material/Paper";
import { format, subYears } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
const steps = [
  "Informações Pessoais",
  "Contatos",
  "Escolaridade",
  "Informações da Ficha",
  "Imagem",
];
const initialMember = {
  name: "",
  street: "",
  cep: "",
  number: "",
  neighborhood: "",
  complement: "",
  city: "",
  state: "",
  email: "",
  phone: "",
  landline: "",
  facebook: "",
  instagram: "",
  schooling: "",
  courses: "",
  occupation_area: "",
  entry_date: "",
  date_of_birth: "",
  type_entry: "BATISMO",
  status: true,
  avatar_id: null,
  marital_status: "SOLTEIRO",
  take_your_medicine: false,
  medicines: "",
  observation: "",
  spouse_name: "",
  spouse_phone: "",
  contacts: "",
};

const ocuppationList = [
  "Adoração",
  "Multimídia",
  "Administrativo",
  "Executivo",
  "Secretaria",
  "Zeladoria",
  "Central Membresia",
  "Atmosfera (Recepção)",
  "Líder de Célula",
  "Auxiliar",
  "Supervisor de Células",
  "Intercessão",
  "Pastoral",
  "BackStage",
  "Kidscipulos",
  "Missões",
  "Assistência Social",
];

function Members() {
  const [member, setMember] = useState(initialMember);
  const [contacts, setContacts] = useState([]);
  const [nameContactEmergency, setNameContactEmergency] = useState("");
  const [phoneContactEmergency, setPhoneContactEmergency] = useState("");
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [edit, setEdit] = useState(false);
  const [occupation_area, setOccupation_area] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [openWebcam, setOpenWebcam] = useState(false);
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState("");
  const [url, setUrl] = useState("");
  const [approach, setApproach] = useState(0);
  const [approachCheck, setApproachCheck] = useState(false);
  const [selectedSpouse, setSelectedSpouse] = useState(false);

  useEffect(() => {
    getInfo();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function getInfo() {
    try {
      setLoading(true);
      const { data } = await api.get("/members");

      let membersArray = [];
      data.forEach((m) => {
        membersArray.push({
          id: m.id,
          nome: m.name,
          email: m.email,
          celular: m.phone,
          fixo: m.landline,
          entradaMembresia: m.entry_date
            ? new Date(m.entry_date).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              })
            : null,
          ocupacoes: m.occupation_area.toUpperCase(),
          status: m.status ? "ATIVO" : "INATIVO",
          spouse_phone: m.spouse_phone,
        });
      });
      setRows(membersArray);

      setLoading(false);
    } catch (error) {
      return toast.error("Erro ao carregar Membros");
    }
  }

  useEffect(() => {
    onChange("occupation_area", occupation_area);
  }, [occupation_area]);

  async function find(id, edit) {
    try {
      setLoading(true);
      const { data } = await api.get(`/members/${id}`);

      setMember(data);
      if (data.avatar) {
        setUrl(data.avatar.url);
      } else {
        setUrl("");
      }

      setMember((member) => ({
        ...member,
        date_of_birth: member.date_of_birth.toString().split("T")[0],
        entry_date: member.entry_date
          ? member.entry_date.toString().split("T")[0]
          : null,
      }));

      if (data.spouse_name !== "") {
        setSelectedSpouse({ id: 0, nome: data.spouse_name || "" });
      }

      setOccupation_area(
        data.occupation_area ? data.occupation_area.toString().split(",") : []
      );
      setNameContactEmergency("");
      setPhoneContactEmergency("");
      if (data.contacts && data.contacts !== "") {
        setContacts(JSON.parse(data.contacts));
      } else {
        setContacts([]);
      }

      if (edit) {
        handleOpen();
      } else {
        setViewModal(true);
      }

      setLoading(false);
    } catch (error) {
      return toast.error("Erro ao carregar Dados");
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      disableClickEventBubbling: true,
      headerClassName: "titleColumn",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "celular",
      headerName: "Celular",
      // type: "number",
      width: 110,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "fixo",
      headerName: "Telefone Fixo",
      // type: "number",
      width: 110,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "entradaMembresia",
      headerName: "Data de Entrada",
      // type: "number",
      width: 110,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "ocupacoes",
      headerName: "Atuante em",
      // type: "number",
      width: 180,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "status",
      headerName: "Situação",
      // type: "number",
      width: 100,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "acoes",
      headerName: "Ações",
      // type: "number",
      width: 250,
      editable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        return (
          <GroupButtons>
            <Button
              variant="contained"
              color="warning"
              endIcon={<Edit />}
              onClick={(event) => {
                reset();
                setEdit(true);
                find(cellValues.row.id, true);
              }}
            >
              Editar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                find(cellValues.row.id, false);
              }}
            >
              <Visibility />
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={(event) => {
                downloadPdf(cellValues.row.id);
              }}
            >
              <PictureAsPdf />
            </Button>
          </GroupButtons>
        );
      },
    },
  ];

  async function handleSubmit() {
    setLoadingSave(true);

    const el = document.querySelectorAll('[aria-invalid="true"]');

    if (el.length > 0) {
      return toast.error("Verifique todos os campos marcados em vermelho.");
    }
    if (!member.avatar_id && !file) {
      setLoadingSave(false);
      return toast.error("É necessário colocar uma foto no cadastro.");
    }

    let avatar_id = member.avatar_id;

    if (file) {
      try {
        const formData = new FormData();
        if (blob !== "") {
          formData.append("file", blob);
        } else {
          formData.append("file", file);
        }

        const { data } = await api.post("/files", formData);

        onChange("avatar_id", data.id);
        avatar_id = data.id;
      } catch (error) {
        setLoadingSave(false);
        return;
      }
    }

    let newMember = member;
    if (member.marital_status === "SOLTEIRO") {
      newMember.spouse_name = null;
      newMember.spouse_phone = null;
    }
    if (member.entry_date === "") {
      newMember.entry_date = null;
    }
    if (edit) {
      try {
        await api.put(`/members/${member.id}`, {
          member: newMember,
          avatar_id: avatar_id,
        });
        toast.success("Membro atualizado com sucesso");
        handleClose();
        await getInfo();
        setLoadingSave(false);
        return setActiveStep(0);
      } catch (error) {
        setLoadingSave(false);
        if (error.request.response === '{"error":"Member already exists "}') {
          return toast.error("Já existe um membro com este número de telefone");
        }
        return toast.error("Erro ao Atualizar dados de membresia");
      }
    } else {
      try {
        await api.post(`/members`, { member: member, avatar_id: avatar_id });
        toast.success("Membro criado com sucesso");
        handleClose();
        await getInfo();
        setLoadingSave(false);
        return setActiveStep(0);
      } catch (error) {
        setLoadingSave(false);
        if (error.request.response === '{"error":"Member already exists "}') {
          return toast.error("Já existe um membro com este número de telefone");
        }
        return toast.error("Erro ao criar dados de membro");
      }
    }
  }
  const onChange = (key, value) => {
    if (typeof value === "string" || value instanceof String) {
      return setMember((member) => ({ ...member, [key]: value.toUpperCase() }));
    }
    setMember((member) => ({ ...member, [key]: value }));
  };

  async function addContactEmergency(name, phone) {
    let newArray = contacts.length === 0 ? [] : contacts;

    if (name === "" || phone === "") {
      return toast.error(
        "Preencher ambos os campos, para adicionar um novo contato"
      );
    }
    if (phone === member.phone) {
      return toast.error(
        "O número do contato não pode ser o mesmo deste cadastro"
      );
    }
    if (contacts.filter((c) => c.phone === phone).length > 0) {
      return toast.error("O número do contato ja está cadastrado");
    }
    newArray.push({
      NAME: name,
      PHONE: phone,
    });
    setContacts([...newArray]);
    let contactString = JSON.stringify(newArray);
    onChange("contacts", contactString);
  }

  async function removeContacts(i) {
    let newArray = contacts;

    newArray.splice(i, 1);

    setContacts([...newArray]);
    let contactString = JSON.stringify(newArray);
    onChange("contacts", contactString);
  }

  async function returnCep() {
    try {
      if (MaskCep(member.cep).length === 9) {
        const { data } = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${member.cep}`
        );
        setMember((member) => ({
          ...member,
          street: data.street ? data.street.toUpperCase() : member.street,
          city: data.city ? data.city.toUpperCase() : member.city,
          state: data.state ? data.state.toUpperCase() : member.state,
          neighborhood: data.neighborhood
            ? data.neighborhood.toUpperCase()
            : member.neighborhood,
        }));
      }
      return;
    } catch (error) {}
  }

  function nextPage() {
    const el = document.querySelectorAll('[aria-invalid="true"]');

    if (el.length > 0) {
      return toast.error("Verifique todos os campos marcados em vermelho.");
    }

    if (activeStep === 0 && !member.marital_status) {
      return toast.error("Selecione o estado civil.");
    }
    if (activeStep === 2 && !member.schooling) {
      return toast.error("Selecione um nível de Formação.");
    }

    if (
      rows.find(
        (m) =>
          m.spouse_phone === member.spouse_phone &&
          m.id !== member.id &&
          member.spouse_phone !== "" &&
          member.spouse_phone !== null
      )
    ) {
      return toast.error("Já existe um cadastro com o mesmo cônjuge");
    }

    setActiveStep(activeStep + 1);
  }

  async function goToMarital() {
    confirmAlert({
      title: "Deseja realizar esta ação?",
      message: "Ao abrir a ficha do cônjuge a atual será fechada.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            if (rows.find((m) => m.spouse_phone === member.phone)) {
              let spouse = rows.find((m) => m.spouse_phone === member.phone);
              find(spouse.id, true);
            } else {
              toast.error("Nenhuma informação encontrada");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  function reset() {
    setEdit(false);
    setApproach(0);
    setApproachCheck(false);
    setMember(initialMember);
    setUrl("");
    setActiveStep(0);
    setFile(null);
    setContacts([]);
  }

  async function downloadPdf(id) {
    try {
      await api.delete(`/pdf/${id}`).then(async () => {
        await api.post(`/pdf/${id}`).then(() => {
          setTimeout(async () => {
            window.open(
              `https://backend.plenitudeigrejabatista.com.br/pdf/${id}`
            );
          }, 2000);
          setTimeout(async () => {
            await api.delete(`/pdf/${id}`);
          }, 5000);
        });
      });
    } catch (error) {}
  }

  async function handleAproach(years) {
    setApproach(years);
    if (years > 0) {
      const result = format(
        subYears(new Date(), parseInt(years)),
        "yyyy-MM-dd"
      );

      onChange("entry_date", result);
    }
  }
  return (
    <>
      <Container>
        <GridContaint>
          <DataGrid
            rows={rows}
            columns={columns}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.status}`
            }
            
          ></DataGrid>
        </GridContaint>
        <GroupButtons>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Add />}
            loadingPosition="start"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              gap: 15,
            }}
            onClick={() => {
              reset();
              handleOpen();
            }}
          >
            Novo
          </Button>
        </GroupButtons>
      </Container>
      {loading ? (
        <></>
      ) : (
        <>
          <Modal open={open} onClose={handleClose} style={ModalStyle}>
            <ContentModal>
              <header>
                <h1>Cadastro de Membros</h1>
              </header>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel id="stepTitle">{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <FormContainer>
                <form>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={PT}
                  >
                    {activeStep === 0 && (
                      <>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Nome"
                            variant="standard"
                            onChange={(e) => onChange("name", e.target.value)}
                            value={member.name}
                            fullWidth
                            error={member.name === ""}
                          />
                        </div>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Data de Nascimento"
                            variant="standard"
                            style={{ width: "20%" }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                              onChange("date_of_birth", e.target.value)
                            }
                            value={member.date_of_birth}
                            error={member.date_of_birth === ""}
                          />

                          <TextField
                            id="standard-basic"
                            label="Endereço"
                            variant="standard"
                            style={{ width: "100%" }}
                            onChange={(e) => onChange("street", e.target.value)}
                            value={member.street}
                            error={member.street === ""}
                          />
                        </div>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Cep"
                            variant="standard"
                            style={{ width: "33%" }}
                            inputProps={{ maxLength: 9 }}
                            onBlur={() => {
                              returnCep();
                            }}
                            onChange={(e) =>
                              onChange("cep", RemoveMask(e.target.value))
                            }
                            value={MaskCep(member.cep)}
                            error={member.cep === ""}
                          />
                          <TextField
                            id="standard-basic"
                            label="Nº"
                            variant="standard"
                            style={{ width: "33%" }}
                            type="number"
                            onChange={(e) => onChange("number", e.target.value)}
                            value={member.number}
                            error={member.number === ""}
                          />
                          <TextField
                            id="standard-basic"
                            label="Complemento"
                            variant="standard"
                            style={{ width: "33%" }}
                            onChange={(e) =>
                              onChange("complement", e.target.value)
                            }
                            value={member.complement}
                          />
                        </div>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Bairro"
                            variant="standard"
                            style={{ width: "33%" }}
                            onChange={(e) =>
                              onChange("neighborhood", e.target.value)
                            }
                            value={member.neighborhood}
                            error={member.neighborhood === ""}
                          />
                          <TextField
                            id="standard-basic"
                            label="Cidade"
                            variant="standard"
                            style={{ width: "33%" }}
                            onChange={(e) => onChange("city", e.target.value)}
                            value={member.city}
                            error={member.city === ""}
                          />
                          <TextField
                            id="standard-basic"
                            label="Estado"
                            variant="standard"
                            style={{ width: "33%" }}
                            inputProps={{ maxLength: 2 }}
                            onChange={(e) => onChange("state", e.target.value)}
                            value={member.state}
                            error={
                              member.state === "" || member.state.length < 2
                            }
                            helperText={
                              member.state === ""
                                ? "Preencha o campo"
                                : member.state.length < 2
                                ? "Mínimo de dois caractéres"
                                : null
                            }
                          />
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <h3 style={{ width: 100 }}>Estado Civil:</h3>
                          <RadioGroup
                            onChange={(v) =>
                              onChange("marital_status", v.target.defaultValue)
                            }
                          >
                            <div className="input">
                              <input
                                type="radio"
                                value="SOLTEIRO"
                                name="marital_status"
                                defaultChecked={
                                  member.marital_status === "SOLTEIRO"
                                    ? true
                                    : false
                                }
                              />
                              Solteiro
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="CASADO"
                                name="marital_status"
                                defaultChecked={
                                  member.marital_status === "CASADO"
                                    ? true
                                    : false
                                }
                              />
                              Casado
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="VIUVO"
                                name="marital_status"
                                defaultChecked={
                                  member.marital_status === "VIUVO"
                                    ? true
                                    : false
                                }
                              />
                              Viúvo
                            </div>
                          </RadioGroup>
                        </div>
                        <div className="row">
                          <Accordion id="accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <h3>Dados de Saude</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <h3 style={{ fontWeight: 400 }}>
                                  Toma remédio controlado ?
                                </h3>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label="Age"
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    if (e.target.value === "false") {
                                      onChange("medicines", "");
                                      onChange("take_your_medicine", false);
                                    } else {
                                      onChange("take_your_medicine", true);
                                    }
                                  }}
                                  value={member.take_your_medicine}
                                >
                                  <MenuItem value="true">
                                    <em>Sim</em>
                                  </MenuItem>
                                  <MenuItem value="false">
                                    <em>Não</em>
                                  </MenuItem>
                                </Select>
                              </div>
                              {member.take_your_medicine && (
                                <div className="row" style={{ height: 40 }}>
                                  <TextField
                                    id="standard-textarea"
                                    label="Nome dos Remédios"
                                    placeholder="Ex: Dipirona"
                                    onChange={(e) => {
                                      onChange("medicines", e.target.value);
                                    }}
                                    value={member.medicines}
                                    multiline
                                    variant="standard"
                                    style={{
                                      width: "100%",
                                    }}
                                    error={
                                      member.take_your_medicine &&
                                      member.medicines === ""
                                    }
                                  />
                                </div>
                              )}
                              <div className="row" style={{ height: 40 }}>
                                <TextField
                                  id="standard-textarea"
                                  label="Outras Observações"
                                  placeholder="Ex: Possui marcapasso"
                                  multiline
                                  variant="standard"
                                  style={{ width: "100%" }}
                                  onChange={(e) => {
                                    onChange("observation", e.target.value);
                                  }}
                                  value={member.observation}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {member.marital_status === "CASADO" && (
                          <div className="row">
                            <Accordion id="accordion">
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <h3>Dados do cônjuge</h3>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="row">
                                  <Autocomplete
                                    options={rows}
                                    style={{ width: "50%", fontWeight: 600 }}
                                    getOptionLabel={(option) => option.nome}
                                    id="free-solo-demo"
                                    freeSolo
                                    value={selectedSpouse}
                                    onInputChange={(e) =>
                                      e
                                        ? onChange(
                                            "spouse_name",
                                            e.target.value
                                          )
                                        : null
                                    }
                                    onChange={(event, newValue) => {
                                      console.log(event);
                                      onChange(
                                        "spouse_name",
                                        newValue ? newValue.nome : null
                                      );
                                      onChange(
                                        "spouse_phone",
                                        newValue ? newValue.celular : null
                                      );
                                      setSelectedSpouse(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Nome"
                                        variant="standard"
                                        error={
                                          member.marital_status === "CASADO" &&
                                          (member.spouse_name === null ||
                                            member.spouse_name === "")
                                        }
                                      />
                                    )}
                                  />

                                  <TextField
                                    id="standard-basic"
                                    label="Telefone Celular"
                                    variant="standard"
                                    style={{ width: "50%" }}
                                    inputProps={{ maxLength: 15 }}
                                    onChange={(e) => {
                                      onChange("spouse_phone", e.target.value);
                                    }}
                                    value={MaskCelNumber(member.spouse_phone)}
                                    error={
                                      member.marital_status === "CASADO" &&
                                      (member.spouse_phone === null ||
                                        member.spouse_phone === "")
                                    }
                                  />
                                </div>
                                <div className="row">
                                  <p id="link" onClick={() => goToMarital()}>
                                    Abrir cadastro do cônjuge
                                  </p>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Telefone Residencial"
                            variant="standard"
                            style={{ width: "33%" }}
                            inputProps={{ maxLength: 14 }}
                            onChange={(e) =>
                              onChange("landline", e.target.value)
                            }
                            value={maskPhoneNumber(member.landline)}
                            error={
                              (member.phone === "" && member.landline === "") ||
                              (member.phone === "" &&
                                member.landline.length < 14) ||
                              (member.landline !== "" &&
                                member.landline.length < 14)
                            }
                            helperText={
                              member.phone === "" && member.landline === ""
                                ? "Preencha um dos telefones"
                                : (member.phone === "" &&
                                    member.landline.length < 14) ||
                                  (member.landline !== "" &&
                                    member.landline.length < 14)
                                ? "Preencha um telefone válido"
                                : null
                            }
                          />
                          <TextField
                            id="standard-basic"
                            label="Telefone Celular"
                            variant="standard"
                            style={{ width: "33%" }}
                            inputProps={{ maxLength: 15 }}
                            onChange={(e) => onChange("phone", e.target.value)}
                            value={MaskCelNumber(member.phone)}
                            error={
                              (member.landline === "" && member.phone === "") ||
                              (member.landline === "" &&
                                member.phone.length < 15) ||
                              (member.phone !== "" && member.phone.length < 15)
                            }
                            helperText={
                              member.landline === "" && member.phone === ""
                                ? "Preencha um dos telefones"
                                : (member.landline === "" &&
                                    member.phone.length < 15) ||
                                  (member.phone !== "" &&
                                    member.phone.length < 15)
                                ? "Preencha um telefone válido"
                                : null
                            }
                          />
                          <TextField
                            label="E-mail"
                            variant="standard"
                            style={{ width: "33%" }}
                            type="email"
                            onChange={(e) => onChange("email", e.target.value)}
                            value={member.email}
                            error={
                              member.email === ""
                                ? false
                                : ValidateEmail(member.email)
                                ? false
                                : true
                            }
                          />
                        </div>

                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Instagram"
                            variant="standard"
                            style={{ width: "50%" }}
                            onChange={(e) =>
                              onChange("facebook", e.target.value)
                            }
                            value={member.facebook}
                          />
                          <TextField
                            id="standard-basic"
                            label="Facebook"
                            variant="standard"
                            style={{ width: "50%" }}
                            onChange={(e) =>
                              onChange("instagram", e.target.value)
                            }
                            value={member.instagram}
                          />
                        </div>

                        <div className="row">
                          <Accordion id="accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <h3>Contatos de emergência</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <TextField
                                  id="standard-basic"
                                  label="Nome"
                                  variant="standard"
                                  style={{ width: "100%" }}
                                  onChange={(e) => {
                                    setNameContactEmergency(e.target.value);
                                  }}
                                  value={nameContactEmergency}
                                  fullWidth
                                />
                                <TextField
                                  id="standard-basic"
                                  label="Telefone Celular"
                                  variant="standard"
                                  style={{ width: "100%" }}
                                  inputProps={{ maxLength: 15 }}
                                  onChange={(e) => {
                                    setPhoneContactEmergency(e.target.value);
                                  }}
                                  value={MaskCelNumber(phoneContactEmergency)}
                                />
                                <GroupButtons style={{ width: "33%" }}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={(event) => {
                                      addContactEmergency(
                                        nameContactEmergency,
                                        phoneContactEmergency
                                      );
                                    }}
                                  >
                                    <Add />
                                  </Button>
                                </GroupButtons>
                              </div>
                              {contacts.length > 0 && <h2>Contatos</h2>}
                              {contacts.map((contact, i) => (
                                <div
                                  className="row"
                                  style={{ marginBottom: 10 }}
                                >
                                  <Paper
                                    elevation={3}
                                    style={{
                                      width: 300,
                                      height: 40,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  >
                                    <p>
                                      {contact.NAME} : {contact.PHONE}
                                    </p>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => {
                                        removeContacts(i);
                                      }}
                                    >
                                      <Close />
                                    </Button>
                                  </Paper>
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <div className="row" style={{ marginTop: 10 }}>
                          <h3>Formação:</h3>
                          <RadioGroup
                            onChange={(v) =>
                              onChange("schooling", v.target.defaultValue)
                            }
                          >
                            <div className="input">
                              <input
                                type="radio"
                                value="FUNDAMENTAL"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "FUNDAMENTAL"
                                    ? true
                                    : false
                                }
                              />
                              Fundamental
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="ENSINO MÉDIO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "ENSINO MÉDIO"
                                    ? true
                                    : false
                                }
                              />
                              Ensino Médio
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="SUPERIOR INCOMPLETO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "SUPERIOR INCOMPLETO"
                                    ? true
                                    : false
                                }
                              />
                              Superior Incompleto
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="SUPERIOR COMPLETO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "SUPERIOR COMPLETO"
                                    ? true
                                    : false
                                }
                              />
                              Superior Completo
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="SUPERIOR CURSANDO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "SUPERIOR CURSANDO"
                                    ? true
                                    : false
                                }
                              />
                              Superior Cursando
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="MESTRADO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "MESTRADO" ? true : false
                                }
                              />
                              Mestrado
                            </div>
                            <div className="input">
                              <input
                                type="radio"
                                value="DOUTORADO"
                                name="scholing"
                                defaultChecked={
                                  member.schooling === "DOUTORADO"
                                    ? true
                                    : false
                                }
                              />
                              Doutorado
                            </div>
                          </RadioGroup>
                        </div>
                        <div className="row">
                          <TextField
                            id="standard-basic"
                            label="Cursos"
                            maxRows={4}
                            variant="standard"
                            style={{ width: "50%" }}
                            onChange={(e) =>
                              onChange("courses", e.target.value)
                            }
                            value={member.courses}
                          />
                        </div>
                      </>
                    )}
                    {activeStep === 3 && (
                      <>
                        <div className="row">
                          <div className="groupCheckbox">
                            <h3>Atuante em:</h3>

                            <CheckboxGroup
                              name="occupation_area"
                              value={occupation_area}
                              onChange={(v) => setOccupation_area(v)}
                            >
                              {(Checkbox) => (
                                <GroupCheckbox>
                                  {ocuppationList.sort().map((ocuppation) => (
                                    <div className="input">
                                      <Checkbox value={`${ocuppation}`} />
                                      {`${ocuppation}`}
                                    </div>
                                  ))}
                                </GroupCheckbox>
                              )}
                            </CheckboxGroup>
                          </div>
                        </div>

                        <div className="row" style={{ alignItems: "center" }}>
                          <TextField
                            id="standard-basic"
                            label="Data de Entrada na Membresia"
                            variant="standard"
                            style={{ width: "17%" }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                              onChange("entry_date", e.target.value)
                            }
                            value={member.entry_date}
                            // error={member.entry_date === ""}
                          />
                          <div
                            className="input"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                          >
                            <input
                              type="checkbox"
                              onChange={() => setApproachCheck(!approachCheck)}
                            />
                            Aproximar
                          </div>
                          {approachCheck && (
                            <TextField
                              id="standard-basic"
                              label="Quantidade em anos"
                              variant="standard"
                              style={{ width: "17%" }}
                              type="number"
                              onChange={(e) => handleAproach(e.target.value)}
                              value={approach}
                            />
                          )}
                        </div>
                        <div className="info">
                          <div className="info_group">
                            <div className="shape">
                              <h3>Tipo de Membresia:</h3>
                              <RadioGroup
                                onChange={(v) =>
                                  onChange("type_entry", v.target.defaultValue)
                                }
                              >
                                <div className="input">
                                  <input
                                    type="radio"
                                    value="ACLAMAÇÃO"
                                    name="type"
                                    defaultChecked={
                                      member.type_entry === "ACLAMAÇÃO"
                                        ? true
                                        : false
                                    }
                                  />
                                  Aclamação
                                </div>
                                <div className="input">
                                  <input
                                    type="radio"
                                    value="BATISMO"
                                    name="type"
                                    defaultChecked={
                                      member.type_entry === "BATISMO"
                                        ? true
                                        : false
                                    }
                                  />
                                  Batismo
                                </div>
                                <div className="input">
                                  <input
                                    type="radio"
                                    value="RECONCILIAÇÃO"
                                    name="type"
                                    defaultChecked={
                                      member.type_entry === "RECONCILIAÇÃO"
                                        ? true
                                        : false
                                    }
                                  />
                                  Reconciliação
                                </div>
                              </RadioGroup>
                            </div>

                            <div className="status">
                              <RadioGroup
                                onChange={(v) =>
                                  onChange(
                                    "status",
                                    v.target.defaultValue === "Ativo"
                                      ? true
                                      : false
                                  )
                                }
                              >
                                <h3>Situação Atual:</h3>
                                <div className="input">
                                  <input
                                    type="radio"
                                    value="Ativo"
                                    name="status"
                                    defaultChecked={
                                      member.status ? true : false
                                    }
                                  />
                                  Ativo
                                </div>
                                <div className="input">
                                  <input
                                    type="radio"
                                    value="Inativo"
                                    name="status"
                                    defaultChecked={
                                      !member.status ? true : false
                                    }
                                  />
                                  Inativo
                                </div>
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {activeStep === 4 && (
                      <>
                        <GroupButtons>
                          <Button
                            variant="contained"
                            color="secondary"
                            loadingPosition="start"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setOpenWebcam(!openWebcam);
                            }}
                          >
                            <CameraAlt />
                          </Button>
                        </GroupButtons>
                        <AvatarInput
                          callbackParent={({ file, openWebcam, blob }) => {
                            setBlob(blob);
                            setFile(file);
                            setOpenWebcam(openWebcam);
                          }}
                          url={url}
                          openWebCam={openWebcam}
                        />
                      </>
                    )}
                  </LocalizationProvider>
                </form>
              </FormContainer>
              {activeStep === 4 && (
                <GroupButtons>
                  <Button
                    variant="contained"
                    color="warning"
                    endIcon={<ArrowLeft />}
                    loadingPosition="start"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      gap: 15,
                    }}
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<SaveAs />}
                    loadingPosition="start"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      gap: 15,
                    }}
                    onClick={async () => {
                      if (!loadingSave) handleSubmit();
                    }}
                  >
                    {!loadingSave ? "Salvar" : <CircularProgress size={32} />}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    endIcon={<Close />}
                    loadingPosition="start"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      gap: 15,
                    }}
                    onClick={() => {
                      handleClose();
                      setActiveStep(0);
                    }}
                  >
                    Cancelar
                  </Button>
                </GroupButtons>
              )}
              {activeStep !== 4 && (
                <GroupButtons>
                  {activeStep !== 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowLeft />}
                      loadingPosition="start"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                        gap: 15,
                      }}
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                      }}
                    >
                      Voltar
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="error"
                    endIcon={<ArrowRight />}
                    loadingPosition="start"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      gap: 15,
                    }}
                    onClick={() => {
                      nextPage();
                    }}
                  >
                    Próximo
                  </Button>
                </GroupButtons>
              )}
            </ContentModal>
          </Modal>
          <Modal
            open={viewModal}
            onClose={() => {
              setViewModal(false);
            }}
            style={ModalStyle}
          >
            <ContentModalView>
              <header>
                <h1>Ficha de Membro</h1>
              </header>
              <div className="group">
                <div className="infos">
                  <h3>Nome:</h3>
                  {member.name}
                  <h3>Email:</h3>
                  {member.email}
                  <h3>Celular:</h3>
                  {member.phone}
                  <h3>Cursos:</h3>
                  <p style={{ maxWidth: 200 }}>
                    {member.courses.split(",").map((course, index) => {
                      if (member.courses.split(",").length - 1 === index) {
                        return `${course}`;
                      }
                      return `${course},  `;
                    })}
                  </p>
                </div>

                <ImageContent>
                  <Img src={url} />
                </ImageContent>
              </div>
            </ContentModalView>
          </Modal>
        </>
      )}
    </>
  );
}

export default Members;
