/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";

import { Container } from "./styles";
import { Form, Input } from "@rocketseat/unform";
import api from "../../services/api";
import { toast } from "react-toastify";
import { createUser } from "../../util/Login";
import InputMask from "react-input-mask";
import history from "../../services/history";
function Register() {
  const [checkout, setCheckout] = useState(false);
  const [phone, setphone] = useState(false);

  async function handleSubmit(data) {
    if (checkout) {
      try {
        await api.post("/usersWeb", {
          phone,
          name: data.inpt_name,
        });
        toast.success("Usuario Cadastrado com Sucesso");
        history.push("/Login");
      } catch (error) {
        if ((error.request.response = { error: "User already exists" })) {
          toast.error("Usuário ja cadastrado");
        } else {
          toast.error(
            "Falha ao Cadastrar Usuário entre em contato com o Administador"
          );
        }
      }
    } else {
      if (data.inpt_pass !== data.inpt_pass_again) {
        toast.error("As senhas devem ser iguais");
      } else {
        const user = await createUser(data.inpt_user, data.inpt_pass);
        if (user) {
          try {
            await api.post("/users", {
              email: data.inpt_user,
              name: data.inpt_name,
            });
            toast.success("Usuario Cadastrado com Sucesso");
          } catch (error) {
            if ((error.request.response = { error: "User already exists" })) {
              toast.error("Usuário ja cadastrado");
            } else {
              toast.error(
                "Falha ao Cadastrar Usuário entre em contato com o Administador"
              );
            }
          }
        }
      }
    }
  }
  return (
    <Container>
      <div className="background">
        <video loop autoPlay muted>
          <source src="videos/blue_particles.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container-login">
        <h1>Cadastrar-se</h1>
        <Form onSubmit={handleSubmit}>
          <p>{!checkout ? "Email" : "Telefone"}:</p>
          {!checkout ? (
            <Input type="email" name="inpt_user" />
          ) : (
            <InputMask
              mask="(99) 99999-9999"
              value={phone}
              onChange={(v) => setphone(v.target.value)}
            />
          )}
          <p>Nome:</p>
          <Input type="text" name="inpt_name" />
          <div className="check">
            <Input
              type="checkbox"
              name="inpt_check"
              id="inpt_check"
              onChange={() => setCheckout(!checkout)}
            />
            <label htmlFor="inpt_check">Usar o telefone como usuario</label>
          </div>

          {!checkout && (
            <>
              <p>Senha:</p>
              <Input type="password" name="inpt_pass" />
              <p>Repetir a senha:</p>
              <Input type="password" name="inpt_pass_again" />
            </>
          )}
          <button type="submit">Salvar</button>
        </Form>
      </div>
    </Container>
  );
}

export default Register;
