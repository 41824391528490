import { MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Main } from "./styles";
import api from "../../../../services/api";
function ContentCourse() {
  const [categoryCourses, setCategoryCourse] = useState([]);
  const [filter, setFilter] = useState([]);
  const [selectFilter, setSelectFilter] = useState("");
  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    let { data: categories } = await api.get("/categoryCourse");
    let filters = [];
    categories.forEach((c) => {
      if (c.courses.length > 0) {
        filters.push(c.name);
      }
    });
    setFilter(filters);
    setCategoryCourse(categories);
  }
  return (
    <Main>
      <div className="filter">
        <p>
          Filtro:
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={selectFilter}
            label="Caregoria"
            onChange={(v) => setSelectFilter(v.target.value)}
          >
            <MenuItem value="">
              <em>Nenhum</em>
            </MenuItem>
            {filter.map((category) => (
              <MenuItem value={`${category}`}>{category}</MenuItem>
            ))}
          </Select>
        </p>
      </div>
      <div className="groupCourses">
        {categoryCourses
          .filter((category) =>
            selectFilter !== "" ? category.name === selectFilter : true
          )
          .filter((c) => c.courses.length > 0)
          .map((category) => (
            <div className="categoryCourse">
              <h1>{category.name}</h1>

              <div className="courses">
                {category.courses.map((course) => (
                  <Link to={`/descricao/${course.id}`}>
                    <div className="course">
                      <img src={`${course.thumbnail}`} alt="imagem do curso" />
                      <div className="shadow"></div>
                      <h3>{course.name}</h3>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
      </div>
    </Main>
  );
}

export default ContentCourse;
