import styled from "styled-components";
import { darken, lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #001f30; /* Um tom mais escuro para dar um aspecto mais sofisticado */
  padding-top: 80px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .events-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;

      .event {
        background-color: #013547; /* Tom escuro complementando o fundo */
        max-width: 600px;
        height: 150px;
        width: 100%;
        border-radius: 16px;
        margin-bottom: 20px; /* Aumentei o espaçamento entre eventos */
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra para criar profundidade */

        .text-container {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6); /* Aumentei a opacidade para melhorar a legibilidade */
          
          p {
            color: #e0e0e0; /* Cor mais clara para melhor contraste */
            font-size: 2rem;
            :nth-child(2) {
              text-transform: capitalize;
            }
          }
        }

        img {
          height: 100%;
          width: 100%;
          background-size: cover; /* Ajustado para cobrir o container */
          background-position: center;
          border-radius: 16px;
          opacity: 0.85; /* Reduz a opacidade para um efeito mais suave */
        }

        button {
          position: absolute;
          height: 45px;
          border-radius: 16px;
          color: #fff;
          background-color: #f34325;
          width: 150px;
          right: 0;
          bottom: 0;
          margin: 0 10px 10px 0;
          z-index: 10;
          transition: background-color 0.3s ease; /* Transição suave no hover */
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra leve no botão */

          :hover {
            cursor: pointer;
            background-color: ${darken(0.1, "#f34325")};
          }

          :active {
            background-color: ${darken(0.2, "#f34325")};
          }
        }
      }

      p {
        color: #ffffff;
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 400px) {
    .wrapper .events-container {
      width: 100%;

      .event {
        button {
          height: 35px;
          width: 130px;
        }
      }
    }
  }
`;
