import styled from "styled-components";

export const Main = styled.div`
  margin: 20px;
  min-height: 600px;
  .filter {
    width: 100%;
    height: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;

    p {
      display: flex;
      align-items: center;
      width: 150px;
      gap: 10px;
      div {
        width: 100%;
        max-height: 20px;
      }
    }
  }
  .groupCourses {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .categoryCourse {
      width: 100%;
      height: 300px;
      .courses {
        padding: 10px 20px;
        display: flex;
        height: 100%;
        width: 100%;
        overflow-x: auto;
        gap: 10px;
        .course {
          background-color: black;
          position: relative;
          height: 100%;
          flex-shrink: 0;
          border-radius: 16px;
          max-width: 200px;
          width: 200px;
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
            transition: 0.3s ease;
          }
          img {
            max-width: 100%;
            height: 100%;
            background-size: cover;
            border-radius: 16px;
          }

          .shadow {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 10;
            border-radius: 16px;
          }
          h3 {
            position: absolute;
            color: white;
            font-weight: 600;
            font-size: 1.5rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
          }
        }
      }
    }
  }
`;
