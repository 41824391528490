import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #001f30;
  padding-top: 80px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 3rem;
      font-weight: 600;
      color: #e0e0e0;
      max-width: 80%;
      text-align: center;
      margin-bottom: 20px;
    }

    .content {
      margin-top: 20px;
      width: 80%;
      background-color: #013a53;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      p {
        color: #cfcfcf;
        font-weight: 400;
        text-align: justify;
        text-indent: 10px;
        font-size: 1.8rem;
      }

      .info {
        margin-top: 15px;

        p {
          color: #bfbfbf;
          font-weight: 400;
          font-size: 1.6rem;
          display: flex;
          flex-direction: column;
          margin: 5px 0;
        }
      }

      .inscription {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #e0e0e0;
        font-weight: 600;
        font-size: 1.8rem;

        span {
          color: #f0c674;
        }

        .checkbox {
          display: flex;
          align-items: center;
          margin-top: 10px;

          input {
            width: 20px;
            height: 20px;
            accent-color: #33d525;
          }

          label {
            margin-left: 10px;
            font-size: 1.6rem;
            color: #bfbfbf;
          }
        }

        button {
          border-radius: 8px;
          height: 50px;
          width: 160px;
          margin: 20px 0;
          background-color: #33d525;
          color: #fff;
          font-weight: 600;
          transition: background-color 0.3s ease, transform 0.2s ease;

          :hover {
            cursor: pointer;
            background-color: ${darken(0.15, "#33d525")};
            transform: scale(1.05);
          }
        }
      }
    }
  }
`;
