import axios from "axios";
import { toast } from "react-toastify";
import { deleteData, getData } from "../util/localStorage";
import history from "./history";

const api = axios.create({
  baseURL: "https://backend.plenitudeigrejabatista.com.br",
});

api.interceptors.request.use(
  (config) => {
    return getData("user")
      .then((user) => {
        if (user && user.token)
          config.headers.Authorization = `Bearer ${user.token}`;
        return Promise.resolve(config);
      })
      .catch((error) => {
        console.log(error);
        return Promise.resolve(config);
      });
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      deleteData("user");
      deleteData("photo");
      toast.error("Sessão expirada necessário autenticar novamente");
      history.push("/Login");
    }

    return error;
  }
);

export default api;
