import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 70px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.8rem;
      color: #fff;
      margin-left: 20px;
    }
    .text-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 20px;
      align-items: flex-start;
      margin-bottom: 30px;
      p {
        font-size: 1.8rem;
        color: ${darken(0.3, "#fff")};
        text-align: justify;
        text-indent: 1.3rem;
      }
      ul li {
        font-size: 1.8rem;
        color: ${darken(0.3, "#fff")};
        text-align: justify;
        text-indent: 1.3rem;
      }
    }
  }
`;
