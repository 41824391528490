import styled from "styled-components";

export const Container = styled.div`
  background: #0087af;
  width: 100%;
  padding: 72px 0;
  .column-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    color: #fff;
    .column-left {
      h2 {
        font-size: 1.3rem;
        color: #fff;
      }
    }
    .column-right {
      h2 {
        font-size: 1.6rem;
        color: #fff;
      }
      h4 {
        font-size: 1.4rem;
      }
    }
  }
  @media (max-width: 750px) {
    .column-group {
      flex-direction: column;
      justify-content: center;
    }
    .column-left,
    .column-right {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 400px) {
    .column-group .facebook .facebook iframe {
      width: 100% !important;
    }
  }
`;
