import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  width: 100%;
  height: 100vh;
 
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  .background {
    position: relative;
    width: 100%;
    height: 100vh;
    
    overflow:hidden;
    video {
      position: absolute;
      z-index: 0;
      background-size: 100% 100%;
      top: 0px;
      left: 0px; /* fixed to left. Replace it by right if you want.*/
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .container-login {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border-radius: 8px;
    width: 400px;
    height: 500px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    animation: mymove 3s ease forwards;
    animation-iteration-count: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    h1 {
      font-size: 2rem;
      color: black;
      margin-bottom: 20px;
    }

    .button-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      button {
        flex: 1 1 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 150px;
        height: 45px;
        color: #000;
        font-size: 1.6rem;
        border-radius: 8px;
        font-weight: 600;
        :hover {
          cursor: pointer;
        }
        svg {
          margin-right: 5px;
        }
      }
      #btn_facebook {
        margin-right: 10px;
        background-color: #3b5998;
        color: #fff;
        :hover {
          background-color: ${darken(0.2, "#3b5998")};
        }
      }
      #btn_google {
        :hover {
          background-color: ${darken(0.2, "#fff")};
        }
        svg {
          fill: red;
        }
      }
    }
    Form {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      p {
        width: 80%;
        color: black;
        font-size: 1.6rem;
        font-weight: 600;
        a {
          color: rgba(0, 0, 0, 0.5);
          :hover {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      Input {
        border-radius: 8px;
        height: 45px;
        width: 80%;
        margin-bottom: 5px;
        padding: 0 10px;
      }
      .check {
        width: 80%;
        display: flex;
        align-items: center;
        Input {
          width: 20px;
          height: 20px;
        }
        label {
          font-size: 1.3rem;
          margin-left: 3px;
        }
      }
      button {
        border-radius: 8px;
        height: 45px;
        width: 80%;
        margin: 15px 0;
        background-color: #333333;
        color: white;
        font-weight: 600;
        :hover {
          cursor: pointer;
          background-color: ${darken(0.2, "#333333")};
        }
      }
      .register {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        display: wrap;
      }
    }
    .loading{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p{
        font-size: 2rem;
        font-weight: 400;
      }
      svg{
        color:blue;
      }
    }
  }

  @keyframes mymove {
    from {
      transform: translateY(200px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: 450px) {
    .background {
      iframe {
        display: none;
      }
      video {
        display: block;
      }
    }
    .container-login {
      margin: 0 0 0 0;
      width: 100%;
      height: 70%;
      
    }
  }
  @media (max-width: 350px) {
    .container-login .button-group #btn_facebook {
      margin-right: 0;
    }
  }
`;
