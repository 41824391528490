import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 80px;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 3rem;
      color: #fff;
    }

    p {
      font-size: 2rem;
      color: #fff;
    }
  }
`;
