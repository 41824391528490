/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";

import Footer from "../../../../components/Footer";
import api from "../../../../services/api";
import { getData } from "../../../../util/localStorage";
import Menu from "../Header";
import { Container, Content, GridContaint, GroupButtons } from "./styles";
import { DataGrid } from "@mui/x-data-grid";

import history from "../../../../services/history";
import { ptBR } from "@mui/x-data-grid/locales";

function MyCourses() {
  const [rowsStudying, setRowsStudying] = useState();
  const [rowsFinish, setRowsFinish] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    getInfo();
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID do Curso",
      width: 90,
      disableClickEventBubbling: true,
      headerClassName: "titleColumn",
    },
    {
      field: "curso",
      headerName: "Nome",
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "aulaAtual",
      headerName: "Aula Atual",
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "porcentagem",
      headerName: "Progresso da Aula",
      width: 150,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "status",
      headerName: "Status",
      // type: "number",
      width: 110,
      editable: false,
      disableClickEventBubbling: true,
    },

    {
      field: "acoes",
      headerName: "Ações",
      // type: "number",
      width: 250,
      editable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        return (
          <GroupButtons>
            <Button
              variant="contained"
              color="warning"
              onClick={(event) => {
                forClass(cellValues.row.id);
              }}
            >
              Ver Aulas
            </Button>
          </GroupButtons>
        );
      },
    },
  ];
  async function getInfo() {
    setLoading(true);
    const memberId = await getData("memberId");
    let { data } = await api.get(`/MyCourses/${memberId}`);
    let studying = [];
    data
      .filter((c) => !c.finish)
      .forEach((c) => {
        studying.push({
          id: c.course.id,
          curso: c.course.name,
          aulaAtual: c.class_stop + 1,
          porcentagem: c.percentage + "%",
          status: "Cursando",
        });
      });
    setRowsStudying(studying);
    let finish = [];
    data
      .filter((c) => c.finish)
      .forEach((c) => {
        finish.push({
          id: c.course.id,
          curso: c.course.name,
          aulaAtual: c.class_stop + 1,
          porcentagem: c.percentage + "%",
          status: "Finalizado",
        });
      });
    setRowsFinish(finish);

    setLoading(false);
  }
  async function forClass(id) {
    history.push(`/Aulas/${id}`);
  }
  return (
    <Container>
      <Menu />
      <Content>
        {!loading ? (
          <>
            <h1>
              Cursando
              <Switch
                checked={toggle}
                onChange={() => {
                  setToggle(!toggle);
                }}
              />
              Finalizados
            </h1>
            <GridContaint>
              <DataGrid
                rows={toggle ? rowsFinish : rowsStudying}
                columns={columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              ></DataGrid>
            </GridContaint>
          </>
        ) : (
          <>
            <h1>Carregando...</h1>
          </>
        )}
      </Content>
      <Footer></Footer>
    </Container>
  );
}

export default MyCourses;
