/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../../components/Footer";
import api from "../../../../services/api";
import history from "../../../../services/history";
import { getData } from "../../../../util/localStorage";
import Menu from "../Header";
import { Container, Description } from "./styles";

function DescriptionCourse() {
  let { id } = useParams();
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(true);
  const [registered, setRegistered] = useState(true);
  const [memberId, setMemberId] = useState(true);

  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    setLoading(true);
    const memberId = await getData("memberId");
    setMemberId(memberId);
    let { data } = await api.get(`/courses/${id}/${memberId}`);
    setRegistered(data.registered);

    setCourse(data);
    setLoading(false);
  }

  async function subscribe() {
    try {
      await api.post(`/subscribeCourse`, {
        last_class_finish: -1,
        class_stop: 0,
        percentage: 0,
        user_id: memberId,
        course_id: id,
        finish: false,
      });
      toast.success(`Parabéns você está inscrito no ${course.name}`);
      history.push(`/Aulas/${id}`);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Container>
      <Menu />
      {!loading ? (
        <div className="content">
          <Description>
            <img src={`${course.thumbnail}`} alt="imagem do curso" />
            <div className="contentDescription">
              <h1>{course.name}</h1>
              <p>{course.description}</p>
            </div>
            <div className="options">
              {registered ? (
                <button className="registered">Inscrito</button>
              ) : (
                <button className="inscription" onClick={() => subscribe()}>
                  Inscrever-se
                </button>
              )}
              {!registered ? (
                <button className="registered">Ver Aulas</button>
              ) : (
                <Link to={`/Aulas/${id}`}>
                  <button className="view">Ver Aulas</button>
                </Link>
              )}
            </div>
          </Description>
        </div>
      ) : (
        <>
          <h2>Carregando</h2>
        </>
      )}
      <Footer></Footer>
    </Container>
  );
}

export default DescriptionCourse;
