import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;
export const PopupContainer = styled.div`
  .churchDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      font-weight: 600;
      text-align: center;
      font-size: 1.4rem;
    }

    a {
      button {
        background-color: yellow;
        color: black;
        font-weight: 600;
        width: 100%;
        height: 40px;
        border-radius: 16px;
        padding: 8px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .cellInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      button {
        background-color: yellow;
        color: black;
        font-weight: 600;
        width: 100%;
        height: 40px;
        border-radius: 16px;
        padding: 8px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;
export const Pin = styled.div`
  padding: 9px;
  cursor: pointer;
  background: #fc6963;
  border: 0;
  border-radius: 5px;
  a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
  }
`;
