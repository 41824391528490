import { RoutesComponent } from "./routes";
import { BrowserRouter } from "react-router-dom"; // Substitui Router por BrowserRouter
import GlobalStyle from "./style/global";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./components/ScrollToTop";
import io from "socket.io-client";
import Maintenance from "./pages/Maintenance";
import './style/index.css';
import { UserProvider } from "./Contexts/UserContext";

// Inicializando o socket
const Socket = io("https://backend.plenitudeigrejabatista.com.br", {
  transports: ["websocket"],
});

function App() {
  console.log(process.env.REACT_APP_MAINTENANCE);

  return (
    <div className="App">
      {process.env.REACT_APP_MAINTENANCE === "true" ? (
        <Maintenance />
      ) : (
        <UserProvider> {/* Envolva o BrowserRouter com o UserProvider */}
          <BrowserRouter>
            <ScrollToTop />
            <RoutesComponent />
            <GlobalStyle />
            <ToastContainer autoClose={1500} />
          </BrowserRouter>
        </UserProvider>
      )}
    </div>
  );
}

export { App, Socket };
