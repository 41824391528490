import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../../assets/logoBlack.png";
import api from "../../../../services/api";
import history from "../../../../services/history";
import { getData, storeData } from "../../../../util/localStorage";
import { Header } from "./styles";

function Menu() {
  // const [user, setUser] = useState(false);
  const [photoUrl, setPhotoUrl] = useState();

  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    // const user = await getData("user");
    const photoUrl = await getData("photo");

    setPhotoUrl(photoUrl);
    const { user } = await getData("user");

    const { data } = await api.post(`/getMemberPhone`, {
      phone: user.phone,
    });

    if (!data) {
      toast.error("Necessário se cadastrar na central de Membresia");
      history.push("/");
    } else {
      await storeData("memberId", data.id);
    }
  }

  return (
    <Header>
      <Link to="/cursos">
        <img src={Logo} alt="logo" />
      </Link>
      <div class="dropdown">
        <img
          class="profile"
          src={
            photoUrl
              ? photoUrl
              : "https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png"
          }
          alt="avatar"
        />
        <div class="dropdown-content">
          <Link to={`/MeusCursos`}>Meus Cursos</Link>
          <Link to={`/`}>Sair</Link>
        </div>
      </div>
    </Header>
  );
}

export default Menu;
