import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 80px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 5rem;
      color: #fff;
    }
    .wrapper-images {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      gap: 20px;
      flex-wrap: wrap;
      img {
        flex: 1 1 300;
        border-radius: 16px;
        height: 300px;
        max-width: 400px;
        :hover {
          cursor: pointer;
          transform: scale(1.03);
        }
      }
    }
  }
  @media (max-width: 400px) {
    .wrapper .wrapper-images {
      padding: 0 5px;
      justify-content: center;
    }
    .wrapper .wrapper-images img {
      max-width: 300px;
    }
  }
`;
