import React from "react";
import { Link } from "react-router-dom";
import { Container } from "./styles";

function Albums() {
  return (
    <Container>
      <div className="wrapper">
        <h1>Galeria</h1>
        <div className="wrapper-albuns">
          <Link to="/Galeria/Bernardo">
            <div className="album">
              <img
                src="https://backend.plenitudeigrejabatista.com.br/files/2d0e9a416941598465475b51716b8dda-bernardo2.png"
                alt="capa"
              />
              <h3>Bernardo</h3>
            </div>
          </Link>
          <Link to="/Galeria/Helena">
            <div className="album">
              <img
                src="https://backend.plenitudeigrejabatista.com.br/files/8463e5fd50ab0c969a7575b564ad9e4f-elena1.png"
                alt="capa"
              />
              <h3>Helena</h3>
            </div>
          </Link>
        </div>
      </div>
    </Container>
  );
}

export default Albums;
