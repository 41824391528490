import React from "react";

import { Container } from "./styles";

function CenterCells() {
  return (
    <Container>
      <iframe
        id="report"
        src={`https://plenitudecursos.plenitudeigrejabatista.com.br/index.php/relatorios/`}
        title="Relatório"
      ></iframe>
    </Container>
  );
}

export default CenterCells;
