import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  overflow: auto;

  .worship {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .iframe-overflow {
      position: relative;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: calc(100vh);
      iframe {
        pointer-events: none;
        position: relative;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        top: -80px;
        z-index: 0;
        left: -80px;
        zoom: 1.13;
      }
      iframe > * {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    .text-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.44);
      h1 {
        font-size: 3rem;
        font-weight: 300;
        text-align: center;
        color: #fff;
      }
      h2,
      h3,
      h4,
      h5 {
        color: #fff;
      }
      h2 {
        text-align: center;
      }
      button {
        width: 200px;
        height: 50px;
        background-color: white;
        color: #000;
        margin: 32px 0;
        font-size: 1.5rem;
        font-weight: 700;
        :hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.3);
          color: white;
        }
      }
      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: #fff;
        :hover {
          cursor: pointer;
          color: #f34325;
        }
      }
    }
  }

  .event {
    width: 100%;
  }
  .news {
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .news-container {
      width: 100%;
      margin-top: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        color: #000;
      }
      .cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
          flex: 1 1 200px;
          margin-right: 10px;
          max-width: 350px;
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;

          align-self: flex-start;
          h2,
          p {
            color: black;
            display: flex;

            align-items: center;
            justify-content: center;
          }
          h2 {
            font-size: 1.6rem;
          }

          img {
            width: 350px;
          }
          button {
            width: 283px;
            background-color: transparent;
            color: #000;
            text-align: center;
            border: 2px solid #000;
            font-size: 1.2rem;
            font-weight: 700;
            height: 45px;
            margin-top: 10px;
            :hover {
              background-color: #000;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
    }
    h1 {
      margin-top: 96px;
      margin-bottom: 10px;
      color: #000;
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      min-width: 40%;

      svg {
        fill: #000 !important;
        margin-right: 10px;
        :hover {
          cursor: pointer;
          fill: #f34325 !important;
        }
      }
    }
  }
  @media (max-width: 800px) {
    .cards {
      display: flex;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      width: auto;
    }
  }
  @media (max-width: 1000px) {
    #video {
      display: none;
    }
  }
`;
export const ButtonParallax = styled.button`
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  transition: all 0.3s ease;
  width: 200px;
  height: 55px;
  background-color: white;
  color: #000;
  margin-top: 15px;
  :hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
  }
`;
export const H3Parallax = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  :hover {
    cursor: pointer;
    color: #f34325;
  }
`;
