
export const storeData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(`@${key}`, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const deleteData = async (key) => {
  try {
    localStorage.removeItem(`@${key}`);
  } catch (error) {
    console.log(error.data);
  }
};

export const getData = async (key) => {
  try {
    const jsonValue = localStorage.getItem(`@${key}`);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};
