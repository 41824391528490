/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Container } from "./styles";
import { FaFacebookSquare, FaGoogle } from "react-icons/fa";
import { Form, Input } from "@rocketseat/unform";
import { SignGoogle, SignFacebook, SignIn, SignInWeb } from "../../util/Login";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import Header from "../../components/Header";
import InputMask from "react-input-mask";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../Contexts/UserContext";

function Login() {
  const { pathname: path } = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUser(); // Obtenha o setUser do contexto
  const [checkout, setCheckout] = useState(false);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit({ inpt_user, inpt_pass }) {
    setLoading(true);
    if (checkout) {
      await SignInWeb(phone, navigate, path, setUser); // Passando setUser
    } else {
      await SignIn(inpt_user, inpt_pass, navigate, path, setUser); // Passando setUser
    }
    setLoading(false);
  }

  async function handleGoogle() {
    setLoading(true);
    await SignGoogle(navigate, path, setUser); // Passando setUser
    setLoading(false);
  }

  return (
    <Container>
      <Header />
      <div className="background">
        <video loop autoPlay muted>
          <source src="videos/Institucional.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container-login">
        {!loading ? (
          <>
            <h1>Entrar Com</h1>
            <div className="button-group">
              <button id="btn_facebook" onClick={() => SignFacebook(navigate, path, setUser)}>
                <FaFacebookSquare size={20} /> Facebook
              </button>
              <button id="btn_google" onClick={handleGoogle}>
                <FaGoogle size={20} />
                Google
              </button>
            </div>
            <Form onSubmit={handleSubmit}>
              <p>{!checkout ? "Email" : "Telefone"}:</p>
              {!checkout ? (
                <Input type="email" name="inpt_user" />
              ) : (
                <InputMask
                  mask="(99) 99999-9999"
                  value={phone}
                  onChange={(v) => setPhone(v.target.value)}
                />
              )}
              <div className="check">
                <Input
                  type="checkbox"
                  name="inpt_check"
                  id="inpt_check"
                  onChange={() => setCheckout(!checkout)}
                />
                <label htmlFor="inpt_check">Usar o telefone como usuario</label>
              </div>
              {!checkout && (
                <>
                  <p>
                    Senha: <a href="#">Esqueceu?</a>
                  </p>
                  <Input type="password" name="inpt_pass" />
                </>
              )}
              <button type="submit">Entrar</button>
              <p className="register">
                Não possui uma conta?<Link to="/Register">Cadastrar-se</Link>
              </p>
            </Form>
          </>
        ) : (
          <>
            <div className="loading">
              <CircularProgress color="secondary" />
              <p>Carregando...</p>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default Login;
