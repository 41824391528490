import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 70px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 50%;
      min-width: 350px;
    }
  }
`;
