import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  .wrappedContent {
    min-height: 450px;
    width: 100%;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile {
      border-radius: 50%;
      width: 100px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    padding: 10px 15px;
    .row {
      display: flex;
      gap: 20px;
      width: 100%;
      align-items: center;

      #free-solo-demo-label {
        font-size: 1.3rem;
        font-weight: 600 !important;
      }
      .MuiAutocomplete-root {
        padding-top: 0.5px;
      }
      #accordion {
        width: 100%;
        #panel1a-header {
          width: 100%;
          padding: 0 10px;
        }
        #panel1a-content {
          padding: 0 0 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        #standard-textarea-label {
          font-size: 1.5rem;
        }

        svg {
          fill: black;
        }
      }
      .groupCheckbox {
        h3 {
          font-size: 1.4rem;
        }
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info_group {
      display: flex;
      align-items: center;
      width: 100%;

      gap: 20px;

      .shape {
        display: flex;
        gap: 10px;
        h3 {
          font-size: 1.4rem;
        }
      }
      .status {
        h3 {
          font-size: 1.4rem;
        }
      }
    }
  }
`;
export const RadioGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  .input {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
`;
export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  gap: 15px;
`;
