import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { DataTable } from '../../../components/DataTable';
import { EventForm } from '../../../components/EventForm';
import { RemoveMask } from '../../../util/Validators/RemoveMask';

const CenterEvents = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getInfo();
  }, []);

  async function getInfo() {
    try {
      setLoading(true);
      const { data } = await api.get('/eventsAdmin');
      const eventsArray = data.map((m) => ({
        id: m.id,
        name: m.name,
        date: format(new Date(m.date), "dd-MM-yyyy HH:mm"),
        date_end: format(new Date(m.date_end), "dd-MM-yyyy HH:mm"),
        description: m.description,
      }));
      setRows(eventsArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar Membros');
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome Do Evento' },
    { field: 'description', headerName: 'Descrição' },
    { field: 'date', headerName: 'Data do Evento' },
    { field: 'date_end', headerName: 'Encerramento do Evento' },
  ];

  const handleEdit = async (id) => {
    if (id) {
      setEdit(true)
      setEditId(id)
      setLoading(true);
      const { data } = await api.get(`/events/${id}`);
      data.date = format(new Date(data.date), "yyyy-MM-dd HH:mm").split(' ').join('T')
      data.date_end = format(new Date(data.date_end), "yyyy-MM-dd HH:mm").split(' ').join('T')
      
      setSelectedEvent(data);
      setIsModalOpen(true);
    }

  };

  const handleSave = async (event) => {
    try{
      event.limit_people = parseInt(event.limit_people)
      if (event.free_entry){
        event.value = 0
      }else{
        event.value = RemoveMask(event.value,true)
      }
      if(edit){
        await api.put(`/events/${editId}`, event);
      }else{
        await api.post('/events', event);
      }
     
      toast.success("Evento Cadastrado com sucesso");
      getInfo()
    }catch{
      toast.error("Falha ao cadastrar um novo evento");
    }

    setIsModalOpen(false);
  };

  const handleCloseModal = () => {

    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto my-4 h-full">
      <DataTable columns={columns} data={rows}>
        <button
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition-colors"
          onClick={(e) => {
            const rowId = e.currentTarget.getAttribute('rowId');
            handleEdit(rowId);
          }}
        >
          Editar
        </button>
     
      </DataTable>
      <div className="flex space-x-2 mt-4 justify-end">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          onClick={() => {
            setEdit(false);
            setSelectedEvent(null);
            setIsModalOpen(true);
          }}
        >
          Novo Evento
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center min-w-[100%] max-h-[100%]">
          <div className="bg-white p-6 rounded-lg shadow-lg min-w-[50%] max-h-[80%] overflow-y-auto">
            <EventForm event={selectedEvent} onSubmit={handleSave} onCancel={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CenterEvents;
