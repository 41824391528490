import { Add, Save } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PT from "date-fns/locale/pt-BR";
import {
  Container,
  ContentModalView,
  FormContainer,
  GridContaint,
  GroupButtons,
  ModalStyle,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";

const initialCourse = {
  name: "",
  description: "",
  url: "",
  thumbnail: "",
  category_id: null,
};

function RegisterCourse() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [Course, setCourse] = useState(initialCourse);
  const [categoryCourses, setCategoryCourse] = useState([]);

  useEffect(() => {
    getInfo();
  }, []);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const columns = [
    {
      field: "id",
      headerName: "ID do Curso",
      width: 90,
      disableClickEventBubbling: true,
      headerClassName: "titleColumn",
    },
    {
      field: "curso",
      headerName: "Nome",
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "qtdStudying",
      headerName: "Pessoas Cursando",
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "qtdFinish",
      headerName: "Pessoas que Terminaram",
      width: 150,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "totalStudents",
      headerName: "Alunos Matrículados",
      width: 150,
      editable: false,
      disableClickEventBubbling: true,
    },
    {
      field: "acoes",
      headerName: "Ações",
      // type: "number",
      width: 250,
      editable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        return (
          <GroupButtons>
            <Button
              variant="contained"
              color="error"
              onClick={(event) => {
                // forClass(cellValues.row.id);
              }}
            >
              Editar
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={(event) => {
                // forClass(cellValues.row.id);
              }}
            >
              Ver Alunos
            </Button>
          </GroupButtons>
        );
      },
    },
  ];

  async function getInfo() {
    try {
      let { data: courses } = await api.get("/Courses");

      let { data: categories } = await api.get("/categoryCourse");
      setCategoryCourse(categories);

      let row = [];
      courses.forEach((course) => {
        row.push({
          id: course.courseId,
          curso: course.courseName,
          qtdStudying: course.studentsStudyng,
          qtdFinish: course.studentsFinish,
          totalStudents: course.studentsStudyng + course.studentsFinish,
        });
      });
      setRows(row);
    } catch (error) {
      return toast.error("Falha ao Carregar os dados");
    }
  }
  async function findPlaylist() {
    try {
      if (Course.url.includes("?list")) {
        let { data } = await api.get(`/config`);

        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${
            Course.url.split("list=")[1]
          }&maxResults=50&part=snippet%2CcontentDetails&key=${
            data.youtube_api_key
          }`
        );
        const json = await response.json();

        let items = json.items;
        if (!items || items.length === 0) {
          onChange("thumbnail", "");
          onChange("url", "");
          return toast.error("Insira uma url válida");
        }

        onChange(
          "thumbnail",
          items[0].snippet.thumbnails.high.url ||
            items[0].snippet.thumbnails.default.url ||
            null
        );
      } else {
        return toast.error("Insira uma url válida");
      }
    } catch (error) {
      return toast.error("Falha ao encontrar playlist");
    }
  }
  async function createCourse() {
    try {
      if (Course.url === "") {
        return toast.error("Campo Playlist url é um campo obrigatórilo");
      }
      if (Course.thumbnail === "") {
        return toast.error("PLaylist inválida thumbnail não encontrada");
      }
      if (Course.name === "") {
        return toast.error("Campo Nome do Curso é um campo obrigatórilo");
      }
      if (Course.description === "") {
        return toast.error("Campo Descrição é um campo obrigatórilo");
      }

      if (!Course.category_id) {
        return toast.error("Selecione uma categoria");
      }

      let ret = await api.post("/courses", {
        name: Course.name,
        description: Course.description,
        url: Course.url,
        thumbnail: Course.thumbnail,
        category_id: Course.category_id,
      });
      if (ret.request.response === '{"error":"Course already exists"}') {
        return toast.error(
          "Falha ao gravar novo Curso,verifique se a url já existe"
        );
      }
      handleClose();
      setCourse(initialCourse);
      await getInfo();
      toast.success("Gravado com Sucesso");
    } catch (error) {
      return toast.error(
        "Falha ao gravar novo Curso,verifique se a url já existe"
      );
    }
  }

  const onChange = (key, value) => {
    if (typeof value === "string" || value instanceof String) {
      return setCourse((course) => ({ ...course, [key]: value }));
    }
    setCourse((course) => ({ ...course, [key]: value }));
  };
  return (
    <Container>
      <GridContaint>
        <DataGrid
          rows={rows}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        ></DataGrid>
      </GridContaint>
      <GroupButtons>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Add />}
          loadingPosition="start"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            gap: 15,
          }}
          onClick={() => {
            handleOpen();
          }}
        >
          Novo
        </Button>
      </GroupButtons>
      <Modal open={open} onClose={handleClose} style={ModalStyle}>
        <ContentModalView>
          <div className="group">
            <FormContainer>
              <form>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={PT}>
                  <>
                    <div className="row">
                      <TextField
                        id="standard-basic"
                        label="Nome do Curso"
                        variant="standard"
                        style={{ width: "100%" }}
                        onBlur={() => {}}
                        onChange={(e) => onChange("name", e.target.value)}
                        value={Course.name}
                      />
                    </div>
                    <div className="row">
                      <TextField
                        id="standard-basic"
                        label="Playlist Url"
                        variant="standard"
                        style={{ width: "100%" }}
                        onBlur={() => {
                          findPlaylist();
                        }}
                        onChange={(e) => onChange("url", e.target.value)}
                        value={Course.url}
                      />
                    </div>

                    <div className="row">
                      <TextField
                        id="standard-basic"
                        label="Descrição do curso"
                        variant="standard"
                        style={{ width: "100%" }}
                        onBlur={() => {}}
                        onChange={(e) =>
                          onChange("description", e.target.value)
                        }
                        value={Course.description}
                      />
                    </div>
                    <div className="row">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Categoria
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={Course.category_id}
                          label="Categoria"
                          onChange={(e) => {
                            onChange("category_id", e.target.value);
                          }}
                        >
                          <MenuItem value={null}>
                            <em>Nenhum</em>
                          </MenuItem>
                          {categoryCourses.map((category) => (
                            <MenuItem value={`${category.id}`}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="row">
                      <GroupButtons>
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<Save />}
                          loadingPosition="start"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                            gap: 15,
                          }}
                          onClick={() => {
                            createCourse();
                          }}
                        >
                          Salvar
                        </Button>
                      </GroupButtons>
                    </div>
                  </>
                </LocalizationProvider>
              </form>
            </FormContainer>

            {Course.thumbnail !== "" && Course.url !== "" && (
              <div className="playlist">
                <h4>Thumb</h4>
                <img className="thumbnail" src={`${Course.thumbnail}`} alt="" />

                {/* <div className="videos">
                    {videos.map((v) => (
                      <div className="groupDescriptionVideo">
                        <img
                          src={`${v.snippet.thumbnails.default.url}`}
                          alt=""
                        />
                        <p>{v.snippet.title}</p>
                      </div>
                    ))}
                  </div> */}
              </div>
            )}
          </div>
        </ContentModalView>
      </Modal>
    </Container>
  );
}

export default RegisterCourse;
