import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  gap: 15px;
  position: relative;
  .navigation {
    height: 100%;
    background: #424242;
    box-shadow: 10px 0 0 #099c22;
    width: 70px;
    overflow-x: hidden;
    transition: 0.5s;
    &:hover {
      width: 200px;
    }
    ul {
      width: 100%;
      padding-left: 5px;
      padding-top: 40px;
      li {
        position: relative;
        width: 100%;

        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        a {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          color: #fff;

          .icon {
            position: relative;
            display: block;
            min-width: 60px;
            text-align: center;
            line-height: 70px;
            font-size: 20px;
            z-index: 99;
          }
          .title {
            position: relative;
            display: block;
            padding-left: 10px;
            height: 60px;
            line-height: 60px;
            z-index: 99;
          }
        }
        &.active {
          background-color: #099c22;
          a::before {
            content: "";
            position: absolute;
            top: -30px;
            right: 0;
            width: 30px;
            height: 30px;
            background-color: #424242;
            border-radius: 50%;
            box-shadow: 15px 15px 0 #099c22;
          }
          a::after {
            content: "";
            position: absolute;
            bottom: -30px;
            right: 0;
            width: 30px;
            height: 30px;
            background-color: #424242;
            border-radius: 50%;
            box-shadow: 15px -15px 0 #099c22;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
