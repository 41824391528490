import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { parseISO, formatRelative } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";
import api from "../../services/api";
function Events() {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    try {
      const events = await api.get("/events");
      setEvents(events.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Container>
      <div className="wrapper">
        <div className="events-container">
          {events.length > 0 ? (
            events.map((event) => {
              return (
                <div className="event" key={event.event.id}>
                  <div className="text-container">
                    <p>{event.event.name}</p>
                    <p>
                      {formatRelative(parseISO(event.event.date), new Date(), {
                        locale: ptBR,
                      })}
                    </p>
                  </div>

                  <img src={event.event.avatar_event.url} alt="imagem do evento" />
                  <Link to={`/InfoEvents/${event.event.id}`}>
                    <button>Ver Detalhes</button>
                  </Link>
                </div>
              );
            })
          ) : (
            <p>Não há nenhum evento cadastrado</p>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Events;
