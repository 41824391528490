/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { Container } from "./styles";

function Footer() {
  return (
    <Container>
      <div className="column-group">
        <div className="column-left">
          <h2>PLENITUDE IGREJA BATISTA Cultos</h2>
          <hr />
          <h2>- DOMINGO - celebração 19h</h2>
          <h2>- TERÇA - Renovo 20h</h2>
          <h2>- Sexta - Vigilha 21h</h2>
          <h2>- Domingo á Sábado - Alvorada 06h</h2>
        </div>
        <div className="column-right">
          <h2 className="title">CONTATOS</h2>
          <hr />
          <h2 style={{ margin: 0 }}>ENDEREÇO:</h2>
          <h4>Rua Domingos Terensi, 60</h4>
          <br />
          <h4>Mirandópolis/SP</h4>
          <h2 style={{ margin: 0, marginTop: "13px" }}>INFORMAÇÕES:</h2>
          <h4>(18) 99124-4691</h4> <br />
          <h4>
            <a href="https://wa.me/5518991244691" target="_blank">
              WhatsApp
            </a>
          </h4>
          <br />
          <h4>
            <Link to="/PolicyPrivace">Politica de Privacidade</Link>
          </h4>
        </div>
        <div className="facebook">
          <div className="facebook">
            <h1>Facebook</h1>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpibmirandopolissp&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=930930884107816"
              width="340"
              height="130"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
