import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Central from "../pages/CentralPlenitude/Central";

export default function RouteWrapper({
  isPrivate = false,
  isHeader = false,
  isCentral = false,
}) {
  // Verifica se a rota é privada
  let auth = JSON.parse(localStorage.getItem("@user"));
  if (!auth) {
    auth = { token: false };
  }

  // Se a rota é privada e o usuário não está autenticado, redireciona para o login
  if (isPrivate && !auth.token) {
    return <Navigate to="/Login" />;
  }

  // Se a rota precisa de header
  if (isHeader) {
    return (
      <>
        <Header />
        <Outlet /> {/* Renderiza o componente passado pela rota */}
        <Footer />
      </>
    );
  }

  // Se a rota é da área central, renderiza o layout Central
  if (isCentral) {
    return (
      <Central>
        <Outlet />
      </Central>
    );
  }

  // Renderiza normalmente a rota
  return <Outlet />;
}
