/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import api from "../../services/api";
import { Form, Input } from "@rocketseat/unform";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
function PrintQR() {
  const [inscription, setInscription] = useState(null);
  const [idEvent, setIdEvent] = useState(null);
  const [companers, setCompaners] = useState([]);
  const [phone, setphone] = useState(false);
  useEffect(() => {
    getInfo();
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  async function getInfo() {
    const inscription = query.get("inscription");
    let companersQuery = query.get("companers");
    setIdEvent(companersQuery);
    const companers = await api.get(`/companers/${companersQuery}`);
    setInscription(inscription);
    setCompaners([...companers.data]);
    console.log(companers);
  }

  async function handleSubmit({ inpt_name }) {
    try {
      await api.post("companers", {
        name: inpt_name,
        phone: phone,
        event_id: idEvent,
      });
      toast.success("acompanhante cadastrado com sucesso");
      await getInfo();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Container>
      <div className="wrapper">
        <h1>Apresente este Qr code na entrada</h1>
        {inscription && <QRCode value={inscription} size={256} />}
        {companers.length > 0 && (
          <div className="companers">
            <h2>Acompanhantes</h2>
            {companers.map((value) => (
              <div key={value.id} className="companer">
                <div className="text">
                  <p>Nome: {value.name}</p>
                  <p>Telefone: {value.phone}</p>
                </div>
                <div className="icons">
                  <div
                    className="sub"
                    onClick={async () => {
                      await api.delete(`/companers/${value.id}`);
                      toast.success("acompanhante removido com sucesso");
                      await getInfo();
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="add-companers">
          <h2>Deseja Adicionar acompanhantes ?</h2>
          <Form onSubmit={handleSubmit}>
            <div className="group-input">
              <div className="group-label">
                <label htmlFor="inpt_name">Nome:</label>
                <Input type="text" name="inpt_name" required />
              </div>
              <div className="group-label">
                <label htmlFor="inpt_phone">Telefone:</label>
                <InputMask
                  mask="(99) 99999-9999"
                  value={phone}
                  onChange={(v) => setphone(v.target.value)}
                  name="inpt_phone"
                  required
                />
              </div>
            </div>

            <button type="submit">Cadastrar</button>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default PrintQR;
