import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding-top: 80px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-bottom: 50px;
      text-align: center;
    }
    .companers {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      h2 {
        font-size: 1.6rem;
      }
      .companer {
        width: 40%;
        min-width: 350px;
        height: 60px;
        background-color: #002a40;
        border-radius: 16px;
        margin-bottom: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        .text {
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          color: #fff;
          width: 80%;
          padding: 5px;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
        .icons {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          .sub {
            background-color: #fff;
            width: 28px;
            height: 5px;
            border-radius: 16px;
            :hover {
              cursor: pointer;
              background-color: #f34325;
            }
          }
        }
      }
    }
    .add-companers {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      Form {
        display: flex;
        align-items: center;
        flex-direction: column;
        .group-input {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .group-label {
            margin-top: 5px;
            label {
              font-size: 1.8rem;
            }
            Input {
              border: 3px solid #002a40;
              border-radius: 16px;
              height: 35px;
              padding: 0 5px;
            }
          }
        }
        button {
          width: 150px;
          height: 45px;
          border-radius: 16px;
          background-color: #002a40;
          color: #fff;
          margin-top: 10px;
          :hover {
            cursor: pointer;
            background-color:${darken(0.2, "#002a40")};
          }
        }
      }
    }
  }
  @media (max-width: 400px) {
    .wrapper .companers .companer {
      width: 100%;
    }
  }
`;
