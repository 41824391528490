import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40; /* Fundo primário */

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centralizar conteúdo horizontalmente */
    justify-content: center; /* Centralizar conteúdo verticalmente */
    padding: 20px;

    h3 {
      font-size: 2rem;
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
      font-weight: 600;
      animation: fadeInDown 0.8s ease-out; /* Animação suave para o título */
    }

    .text-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 800px; /* Limite de largura para melhorar a leitura */
      padding: 0 20px;
      align-items: flex-start;
      margin-bottom: 30px;
      background-color: ${darken(0.05, "#002a40")}; /* Leve contraste no fundo */
      border-radius: 12px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
      padding: 20px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Efeito ao passar o mouse */
      }

      p {
        font-size: 1.2rem;
        color: #f4f4f4;
        text-align: justify;
        text-indent: 1.3rem;
        line-height: 1.6;
        margin-top: 10px;
        animation: fadeInUp 0.8s ease-out; /* Animação suave para o texto */
      }
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
