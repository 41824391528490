import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  #report {
    width: 100%;
    height: 100%;
  }
`;
