import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { parseISO, formatRelative } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import api from "../../services/api";
function Events() {
  const [events, setEvents] = useState(null);
  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    try {
      const events = await api.get("/myevents");
      setEvents(events.data.events);
    } catch (error) {
      console.log(error);
    }
  }
  async function deleteInscription(id) {
    try {
      await api.delete(`/inscription/${id}`);
      await getInfo();
    } catch (e) {
      console.log(e);
      toast.error(
        "Não foi possivel apagar esta inscrição, este ingresso ja foi utilzado"
      );
    }
  }
  async function handleDelete(id) {
    confirmAlert({
      title: "Alerta",
      message: "Deseja Realmente apagar seu Ingresso?",
      buttons: [
        {
          label: "Sim",
          onClick: () => deleteInscription(id),
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }
  return (
    <Container>
      <div className="wrapper">
        <div className="events-container">
          {events ? (
            events.map((event) => {
              return (
                <div className="event" key={event.id}>
                  <div className="text-container">
                    <p>{event.name}</p>
                    <p>
                      {formatRelative(parseISO(event.date), new Date(), {
                        locale: ptBR,
                      })}
                    </p>
                  </div>

                  <img src={event.avatar_event.url} alt="imagem do evento" />
                  <div className="groupButton">
                    <Link
                      to={`/PrintQR/?inscription=${event.users_events.inscription}&companers=${event.id}`}
                    >
                      <button id="see_ticket">Ver Ingresso</button>
                    </Link>

                    <button
                      id="del_ticket"
                      onClick={() => {
                        handleDelete(event.id);
                      }}
                    >
                      Deletar Ingresso
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Você não está cadastrado em nenhum evento</p>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Events;
