/* eslint-disable react-hooks/exhaustive-deps */
import { Camera, Person } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import {
  AddImage,
  Container,
  Img,
  Input,
  Label,
  WebcamContainer,
} from "./styles";

function AvatarInput({
  callbackParent,
  url,
  profile = false,
  openWebCam = false,
}) {
  const [file, setFile] = useState("");
  const [url64, setUrl64] = useState("");

  useEffect(() => {
    if (url !== "") {
      setFile(url);
    }
  }, []);

  async function handleChange(event) {
    if (event.target.files.length > 0) {
      setFile(URL.createObjectURL(event.target.files[0]));
      callbackParent({
        file: event.target.files[0],
        openWebCam: false,
        blob: "",
      });
    }
  }

  const videoConstraints = {
    width: 800,
    height: 720,
    facingMode: "user",
  };

  const WebcamCapture = () => {
    const webcamRef = React.useRef(null);

    const capture = async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = await fetch(imageSrc).then((res) => res.blob());
      // setUrl64(imageSrc);
      // const link = document.getElementById("download");
      // link.click();

      setFile(imageSrc);

      callbackParent({
        file: file,
        openWebCam: false,
        blob: blob,
      });
    };

    return (
      <WebcamContainer>
        <Webcam
          audio={false}
          height={200}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={220}
          mirrored
          videoConstraints={videoConstraints}
        />
        <Button
          variant="contained"
          color="warning"
          endIcon={<Camera />}
          loadingPosition="start"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            gap: 15,
          }}
          onClick={(e) => {
            e.preventDefault();
            capture();
          }}
        >
          Capturar
        </Button>
        {true && (
          <a
            style={{ display: "none" }}
            download="perfil.jpg"
            href={url64}
            id="download"
          >
            Download
          </a>
        )}
      </WebcamContainer>
    );
  };
  return (
    <>
      <Container>
        <Label htmlFor="avatar">
          {file !== "" ? (
            <Img src={file} alt={"imagem"} />
          ) : (
            <AddImage>
              {!profile && <h4>Adicionar Imagem</h4>}
              <Avatar
                sx={{ bgcolor: "green", width: 56, height: 56 }}
                variant="rounded"
              >
                <Person fontSize="48" />
              </Avatar>
            </AddImage>
          )}

          <Input
            type="file"
            onChange={(e) => {
              handleChange(e);
            }}
            accept="image/*"
          />
        </Label>
      </Container>
      {openWebCam && <WebcamCapture />}
    </>
  );
}

export default AvatarInput;
