import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 80px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .events-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      .event {
        background-color: white;
        max-width: 600px;
        height: 150px;
        width: 100%;
        border-radius: 16px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        .text-container {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
          p {
            color: #fff;
            font-size: 2rem;
            :nth-child(2) {
              text-transform: capitalize;
            }
          }
        }
        img {
          height: 100%;
          width: 100%;
          background-size: contain;
          background-position: center;
          border-radius: 16px;
        }
        .groupButton {
          display: flex;
          align-items: center;
          position: absolute;
          height: 45px;
          width: 100%;
          bottom: 0;
          left: 0;
          button {
              width: 100%;
              height: 45px;
              color: #fff;
              width: 50%;
              background-color: #f34325;
              right: 0;
              bottom: 0;
              :hover {
                cursor: pointer;
                background-color: ${darken(0.2, "#f34325")};
              }
            }
          a {
            width: 50%;
            height: 100%;
            #see_ticket{
              background-color: #33d525;
              width:100%;
                :hover {
                  cursor: pointer;
                  background-color: ${darken(0.2, "#33d525")};
                }
              }
            }
          }
        }
      }
      p {
        color: #fff;
        font-size: 3rem;
      }
    }
  }
  @media (max-width: 400px) {
    .wrapper .events-container {
      width: 100%;
      .event {
        button {
          height: 35px;
          width: 130px;
        }
      }
    }
  
`;
