import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 999;

  img {
    margin-left: 120px;
    height: 60%;
    width: 150px;
  }

  .menu {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: flex-end;

    nav {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 32px;

      ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;

        li {
          height: 100%;
          display: flex;
          background-color: transparent;
          color: white;
          font-size: 1.1rem;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s, color 0.3s; /* Adicione transições para o hover */
          margin-right: 16px;
          font-weight: 600;
          position: relative;

          a {
            text-decoration: none;
            color: inherit; /* Herda a cor do li */
          }

          svg {
            margin-left: 3px;
          }

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1); /* Adiciona um fundo no hover */
          }

          ul {
            display: none;
            list-style: none;
            position: absolute;
            z-index: 99;
            bottom: -70px;
            left: 3px;
            width: 200px;

            li {
              width: 100%;
              background-color: #f2f2f2;
              height: 36px;

              a {
                width: 100%;
                text-decoration: none;
                color: #000;
                font-weight: 600;
                opacity: 0.5;
                padding: 6px 18px;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }

          &:hover ul {
            display: block; /* Mostra o submenu no hover */
          }
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      min-width: 30%;

      svg {
        &:hover {
          cursor: pointer;
          fill: #f34325 !important; /* Use com cautela */
        }
      }
    }
  }

  .menu-responsive {
    display: none;
  }

  .menu-section-on {
    display: none;
  }

  .menu-section {
    display: none;
  }

  img {
    margin: 0 0 0 20px;
  }

  .menu-logado {
    display: none;
  }

  .menu-responsive[logado="true"] {
    display: flex;
    align-items: center;

    .profile {
      p {
        color: white;
      }
    }

    svg {
      margin-right: 20px;
      margin-left: 15px;

      &:hover {
        cursor: pointer;
        fill: #f34325;
      }
    }
  }

  .menu-section-on {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 0;
    right: 0;
    width: 20%;
    min-width: 280px;
    height: 100vh;
    background-color: #000;
    z-index: 10;
    animation: mymove 1.5s ease forwards;

    .header {
      width: 100%;
      height: 180px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      p {
        position: absolute;
        color: #fff;
        text-transform: uppercase;
        bottom: 0;
        font-size: 1.6rem;
        margin-bottom: 5px;
      }

      #avatar {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 4px solid #fff;
        position: absolute;
        background-color: white;
        background-size: cover;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    nav {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      ul {
        width: 100%;
        list-style: none;

        li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          font-size: 2.5rem;
          color: #fff;
          padding-left: 15px;
          border-bottom: 1px solid #fff;

          .op {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          a {
            width: 100%;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
              margin-left: 5px;
            }
          }

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1); /* Fundo no hover */
          }

          ul {
            display: none;
            transition: 0.3s ease-in;

            li {
              border: 0;

              a {
                color: rgba(255, 255, 255, 0.5);
                text-decoration: none;

                &:hover {
                  color: rgba(255, 255, 255, 1);
                }
              }
            }
          }

          &:hover ul {
            display: block;
            transition: 0.3s ease-in;
          }
        }
      }
    }
  }

  @keyframes mymove {
    from {
      transform: translateX(280px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    img {
      margin: 0 0 0 20px;
    }

    .menu {
      display: none;
    }

    .menu-responsive {
      display: block;

      svg {
        margin-right: 20px;

        &:hover {
          cursor: pointer;
          fill: #f34325;
        }
      }
    }

    .menu-section-on {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      top: 0;
      right: 0;
      width: 20%;
      min-width: 280px;
      height: 100vh;
      background-color: #000;
      z-index: 10;

      svg {
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }

      nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        ul {
          width: 100%;
          list-style: none;

          li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            font-size: 2.5rem;
            color: #fff;
            padding-left: 15px;
            border-bottom: 1px solid #fff;

            .op {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            a {
              width: 100%;
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;

              svg {
                margin-left: 5px;
              }
            }

            &:hover {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.1); /* Fundo no hover */
            }

            ul {
              display: none;
              transition: 0.3s ease-in;

              li {
                border: 0;

                a {
                  color: rgba(255, 255, 255, 0.5);
                  text-decoration: none;

                  &:hover {
                    color: rgba(255, 255, 255, 1);
                  }
                }
              }
            }

            &:hover ul {
              display: block;
              transition: 0.3s ease-in;
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .menu-section-on {
      width: 50%;
      min-width: 255px;
    }
  }
`;
