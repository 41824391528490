/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Container, ButtonParallax, H3Parallax } from "./styles";
import { Parallax } from "react-parallax";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaSpotify,
  FaMicrophoneAlt,
} from "react-icons/fa";
import PlaceLeader from "../../assets/area_de_lideres.jpeg";

import Vibe from "../../assets/vibe.jpeg";

import api from "../../services/api";
import { ptBR } from "date-fns/locale";
import { parseISO, formatRelative } from "date-fns";
import { Link } from "react-router-dom";
function Home() {
  const [events, setEvents] = useState([]);
  const [video, setVideo] = useState();
  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    try {
      const events = await api.get("/events");
      setEvents(events.data);
    } catch (error) {
      console.log(error);
    }
    try {
      await fetchPlaylistData();
    } catch (error) {
      await fetchPlaylistData();
    }
  }
  async function fetchPlaylistData() {
    const response = await fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?playlistId=PLF8Oy3rPkm8OTIFJVrcRHxcTJ928CqXWq&maxResults=150&part=snippet%2CcontentDetails&key=AIzaSyAvZfTEahB4Y5kde4xmqeP2NwFpOUiXMtI"
    );
    const json = await response.json();

    let items = json.items.slice(0, 1);
    setVideo(items);
  }
  return (
    <Container>
      <div className="worship">
        <div className="iframe-overflow">
          <iframe
            id="video"
            src={`https://youtube.com/embed/${
              video ? video[0].contentDetails.videoId : "B64avHzaLR8"
            }?start=769&vq=1080&controls=0&showinfo=0&autohide=1&mute=1&autoplay=1&loop=1&playlist=${
              video ? video[0].contentDetails.videoId : "B64avHzaLR8"
            }`}
          ></iframe>
        </div>
        <div className="text-container">
          {/* <h1>{video ? video[0].snippet.title : "Plenitude Igreja Batista"}</h1> */}
          <h1>Plenitude Igreja Batista</h1>
          {video && <h2>{video[0].snippet.title}</h2>}

          <a
            href={`https://www.youtube.com/watch?v=${
              video ? video[0].contentDetails.videoId : "B64avHzaLR8"
            }`}
            target="_blank"
          >
            <button>ASSISTIR</button>
          </a>
          <a
            href="https://www.youtube.com/channel/UCtwnvw18qb0ReESHiCRiQpg"
            target="_blank"
          >
            <h3>VER MAIS</h3>
          </a>
        </div>
      </div>
      {events.length > 0 &&
        events.map((event, index) => {
          if (index === 0) {
            return (
              <div className="event" key={event.id}>
                <Parallax
                  bgImage={event.event.avatar_event.url}
                  bgImageAlt="Evento"
                  strength={200}
                  bgImageStyle={{
                    maxHeight: 1300,
                    width: "100%",
                    minWidth: 800,
                  }}
                  blur={{ min: 0, max: 2 }}
                >
                  <div
                    style={{
                      height: 700,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <div
                      className="text-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "3rem",
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "300",
                        }}
                      >
                        {event.event.name}
                      </p>
                      <p
                        style={{
                          fontSize: "3rem",
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "300",
                          textTransform: "capitalize",
                        }}
                      >
                        {formatRelative(parseISO(event.date), new Date(), {
                          locale: ptBR,
                        })}
                      </p>
                      <Link to={`/InfoEvents/${event.event.id}`}>
                        <ButtonParallax>Detalhes</ButtonParallax>
                      </Link>
                      <Link to="/Events">
                        <H3Parallax>Mais Eventos</H3Parallax>
                      </Link>
                    </div>
                  </div>
                </Parallax>
              </div>
            );
          }
        })}
      <Parallax>
        <Parallax
          bgImage={PlaceLeader}
          bgImageAlt="Área de Lideres"
          strength={200}
          bgImageStyle={{ maxHeight: 1300, width: "100%", minWidth: 800 }}
        >
          <div
            style={{
              height: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonParallax>Ver Mais</ButtonParallax>
          </div>
        </Parallax>
      </Parallax>
      <div className="news">
        <div className="news-container">
          <h1>Notícias</h1>
          <div className="cards">
            <div className="card">
              <img src={Vibe} alt="VIBE" />
              <h2>V.I.B.E</h2>
              <p>Confira as imagens clicando abaixo</p>
              <button>Ver Mais</button>
            </div>
          </div>
        </div>
        <h1>Nos Siga</h1>
        <div className="icons">
          <a
            href="https://www.facebook.com/pibmirandopolissp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={32} color={"black"} />
          </a>
          <a
            href="https://www.instagram.com/pibmirandopolis/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={32} color={"black"} />
          </a>
          <a
            href="https://www.youtube.com/pibmirandopolis?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={32} color={"black"} />
          </a>
          <a
            href="https://open.spotify.com/user/08iijmltl2bw9729dy746scrg?si=HqIdmkceSteWycUxqypkHg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSpotify size={32} color={"black"} />
          </a>

          <a
            href="https://castbox.fm/channel/PIBCast-%7C-Plenitude-Igreja-Batista-id2494870?country=br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaMicrophoneAlt size={32} />
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Home;
