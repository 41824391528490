import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;
export const GridContaint = styled.div`
  width: 100%;
  height: 70%;
  overflow: auto;
  background-color: white;
  .super-app-theme--INATIVO {
    background-color: rgba(240, 0, 28, 0.6) !important;
    &:hover {
      background-color: rgba(240, 0, 28, 0.6) !important;
    }
  }
  .MuiDataGrid-columnHeader{
    font-weight: 600 !important;
    color: black;
    font-size: 1rem;
  }
  .MuiDataGrid-columnHeaders {
    background-color: black;
    color: white;
  }
  #mui-2 {
    font-size: 1rem !important;
    color: black;
  }
  .MuiTablePagination-actions {
    button {
      svg {
        fill: black;
      }
    }
  }
`;

export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  gap: 15px;
`;
export const ContentModal = styled.div`
  width: 90%;
  max-height: 90%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  overflow: auto;
  #link {
    margin-top: 10px;
    color: blue;
    font-size: 1.2rem;
    text-decoration: underline;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  #stepTitle span {
    font-size: 1.5rem;
    svg {
      width: 20px;
      height: 20px;
      text {
        font-size: 1.4rem;
      }
    }
  }
`;
export const ContentModalView = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  .group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export const ImageContent = styled.div`
  max-width: 200px;
  max-height: 200px;
  height: 200px;
  width: 200px;
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  background: #eee;
  background-size: cover;
  border-radius: 50%;
`;
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    padding: 10px 15px;
    .row {
      display: flex;
      gap: 20px;
      width: 100%;
      align-items: center;
      #free-solo-demo-label {
        font-size: 1.3rem;
        font-weight: 600 !important;
      }
      .MuiAutocomplete-root {
        padding-top: 0.5px;
      }
      #accordion {
        width: 100%;
        #panel1a-header {
          width: 100%;
          padding: 0 10px;
        }
        #panel1a-content {
          padding: 0 0 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        #standard-textarea-label {
          font-size: 1.5rem;
        }

        svg {
          fill: black;
        }
      }
      .groupCheckbox {
        h3 {
          font-size: 1.4rem;
        }
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info_group {
      display: flex;
      align-items: center;
      width: 100%;

      gap: 20px;

      .shape {
        display: flex;
        gap: 10px;
        h3 {
          font-size: 1.4rem;
        }
      }
      .status {
        h3 {
          font-size: 1.4rem;
        }
      }
    }
  }
`;
export const RadioGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  .input {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
`;

export const GroupCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  .input {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
`;

export const ModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
