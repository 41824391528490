import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import Raleway from "./fonts";

export default createGlobalStyle`
  ${Raleway}


  *{
    padding:0px ;
    border:0px ;
    margin:0px;  
    box-sizing:border-box ;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    outline:0;
    max-width:2000px;
  }
  html{
    font-size:62.5%;
  }
  input{
    color:black;
  }
  svg{
    color:white;
  }
  a{
    color:white;
    text-decoration:none;
  }
  .Toastify__toast-body{
    font-size:1.6rem;
  }
  #standard-basic-label	{
    font-weight:700 !important;
    font-size:1.3rem !important;
  }
  #react-confirm-alert{
    z-index:9999;
    position:absolute;
  }
  
`;
