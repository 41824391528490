import { storeData } from "../localStorage";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseConfig from "../../firebaseconfig";
import api from "../../services/api";
import { toast } from "react-toastify";

// Inicialize o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Função de login com email e senha
export const SignIn = async (email, password, navigate, path,setUser) => {
  let apiUser = null;

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    apiUser = await api.post("sessions", { email });

    if (user && apiUser) {
      storeData("user", apiUser.data);
      setUser(apiUser.data)
      navigate(path !== "/Login" ? path : "/"); // Uso do ternário aqui
    }
  } catch (error) {
    toast.error("Algo deu Errado! Verifique seu Usuário e Senha");
  }
};

// Função de login com telefone
export const SignInWeb = async (phone, navigate, path,setUser) => {
  try {
    const apiUser = await api.post("sessionsWeb", { phone });
    storeData("user", apiUser.data);
    setUser(apiUser.data)
    navigate(path !== "/Login" ? path : "/"); // Uso do ternário aqui
  } catch (error) {
    if (error.request?.response?.error === "User not found") {
      toast.error("Usuário não existe");
    } else {
      toast.error("Falha ao fazer Login do Usuário entre em contato com o Administrador");
    }
  }
};

// Função de login com Google
export const SignGoogle = async (navigate, path,setUser) => {
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const response = await api.post("sessions", { email: user.email });
    storeData("user", response.data);
    storeData("photo", user.photoURL);
    setUser(response.data)

    navigate(path !== "/Login" ? path : "/"); // Uso do ternário aqui
  } catch (err) {
    toast.error("Erro ao fazer login, tente novamente");
  }
};

// Função de login com Facebook
export const SignFacebook = async (navigate, path,setUser) => {
  const provider = new FacebookAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const response = await api.post("sessions", { email: user.email });
    storeData("user", response.data);
    storeData("photo", user.photoURL);
    setUser(response.data)

    navigate(path !== "/Login" ? path : "/"); // Uso do ternário aqui
  } catch (error) {
    toast.error("Erro ao fazer login, tente novamente");
  }
};

// Função para criar novo usuário
export const createUser = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    toast.success("Usuário criado com sucesso!");
    return true;
  } catch (error) {
    const errorMessage = error.message;
    if (errorMessage.includes("email address is already in use")) {
      toast.error("O endereço de e-mail já está sendo usado por outra conta.");
    } else {
      toast.error(errorMessage);
    }
    return false;
  }
};
