const firebaseConfig= {
  apiKey: "AIzaSyB8ag-1guwaF8yFMqKBvP0Ywx6ktrMreVk",
  authDomain: "plenitude-a4c17.firebaseapp.com",
  databaseURL: "https://plenitude-a4c17.firebaseio.com",
  projectId: "plenitude-a4c17",
  storageBucket: "plenitude-a4c17.appspot.com",
  messagingSenderId: "144174450796",
  appId: "1:144174450796:web:f5038799ab4750aa0d3049",
  measurementId: "G-DDCVHR34T6",
};
export default firebaseConfig;
