import { useEffect, useState } from 'react';

const ImageUploader = ({ onImageUpload,url }) => {
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (url !== "") {
        setImage(url);
    }
  }, []);
  // Função para obter a imagem como blob
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Mostra a imagem como preview
        onImageUpload(file); // Passa o arquivo (blob) de volta para o formulário
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    onImageUpload(null); // Remove a imagem do formulário
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative mt-2 w-32 h-32">
        {image ? (
          <>
            <img
              src={image}
              alt="Avatar Preview"
              className="w-full h-full object-cover rounded"
            />
            <button
              type="button"
              onClick={removeImage}
              className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
            >
              ✕
            </button>
          </>
        ) : (
          <div className="w-full h-full bg-gray-200 rounded flex items-center justify-center">
            <span className="text-gray-500">Adicionar imagem</span>
          </div>
        )}
      </div>

      <label
        htmlFor="imageUpload"
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded cursor-pointer"
      >
        {image ? "Alterar Imagem" : "Adicionar Imagem"}
      </label>
      <input
        id="imageUpload"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleImageChange}
      />
    </div>
  );
};

export  {ImageUploader};
