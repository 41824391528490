import React from 'react';

const DataTable = ({ columns, data, children }) => {
  return (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th key={column.field} className="py-3 px-6 text-left text-gray-600 font-semibold uppercase tracking-wider">
                {column.headerName}
              </th>
            ))}
            <th className="py-3 px-6 text-left text-gray-600 font-semibold uppercase tracking-wider">Ações</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.length > 0 ? (
            data.map((row) => (
              <tr key={row.id} className="hover:bg-gray-100 transition-colors">
                {columns.map((column) => (
                  <td key={column.field} className="py-4 px-6 text-gray-800 whitespace-nowrap">
                    {row[column.field]}
                  </td>
                ))}
                <td className="py-4 px-6 text-gray-800">
                  <div className="flex space-x-2">
                    {React.Children.map(children, (child) =>
                      React.cloneElement(child, { rowId: row.id })
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="py-4 px-6 text-center text-gray-500">
                Nenhum dado disponível
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export { DataTable };
