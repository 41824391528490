import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "./styles";

function Albums() {
  let { name } = useParams();

  let Bernardo = [
    "https://backend.plenitudeigrejabatista.com.br/files/d8049db0a514856e07a1e2c7b27290f1-bernardo1.png",
    "https://backend.plenitudeigrejabatista.com.br/files/2d0e9a416941598465475b51716b8dda-bernardo2.png",
    "https://backend.plenitudeigrejabatista.com.br/files/3234b358bfad10f8d897ef47c4b6c96d-bernardo3.png",
    "https://backend.plenitudeigrejabatista.com.br/files/c69420c41ea6252e354669adbd79aa44-bernardo4.png",
    "https://backend.plenitudeigrejabatista.com.br/files/c062a972669c8a827d6a8f1ee1f51a94-bernardo5.png",
  ];
  let Helena = [
    "https://backend.plenitudeigrejabatista.com.br/files/8463e5fd50ab0c969a7575b564ad9e4f-elena1.png",
    "https://backend.plenitudeigrejabatista.com.br/files/34772b79b90b521ef74484728bed0b88-elena2.jpg",
    "https://backend.plenitudeigrejabatista.com.br/files/9fd4b7c5dee441a86a080eee552bcc93-elena3.png",
    "https://backend.plenitudeigrejabatista.com.br/files/22e8b57d92a49ddea244e9f6f6944229-elena4.png",
    "https://backend.plenitudeigrejabatista.com.br/files/aeb0c078375d1b6d531cc45357c572a4-elena5.png",
  ];

  return (
    <Container>
      <div className="wrapper">
        <h1>{name}</h1>

        {name === "Bernardo" && (
          <div className="wrapper-images">
            {Bernardo.map((url) => (
              <a target="_blank" rel="noreferrer" href={url}>
                <img src={url} alt="Imagem Bernardo" />
              </a>
            ))}
          </div>
        )}
        {name === "Helena" && (
          <div className="wrapper-images">
            {Helena.map((url) => (
              <a target="_blank" rel="noreferrer" href={url}>
                <img src={url} alt="Imagem Helena" />
              </a>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
}

export default Albums;
