import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background-color: #e5f8f5;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 600px;
  padding: 20px;
  gap: 15px;
`;
export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  gap: 15px;
`;

export const GridContaint = styled.div`
  width: 100%;
  height: 70%;
  overflow: auto;
  background-color: white;

  .MuiDataGrid-root {
    font-weight: 600 !important;
    color: black;
    font-size: 1rem;
  }
  .MuiDataGrid-columnHeaders {
    background-color: black;
    color: white;
  }
  #mui-2 {
    font-size: 1rem !important;
    color: black;
  }
  .MuiTablePagination-actions {
    button {
      svg {
        fill: black;
      }
    }
  }
`;
