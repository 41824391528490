/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import Footer from "../../../../components/Footer";
import Menu from "../Header";
import { Container } from "./styles";
import { Lock } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { getData } from "../../../../util/localStorage";
import api from "../../../../services/api";
import { Socket } from "../../../../App";
import { confirmAlert } from "react-confirm-alert";
function PlayCourse() {
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState();
  const [numberVideo, setNumberVideo] = useState(0);
  const [interval, setIntervalType] = useState();
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [positionVideoFinish, setPositionVideoFinish] = useState(-1);
  const [percentage, setPercentage] = useState(0);
  const [percentageOld, setPercentageOld] = useState(-1);
  // const [percentageTotal, setPercentageTotal] = useState(0);
  const [returnSubscribe, setReturnSubscribe] = useState({ percentage: 0 });
  const [startVideo, setStartVideo] = useState(0);

  const playerRef = useRef();

  useEffect(() => {
    fetchPlaylistData();
  }, []);

  useLayoutEffect(() => {
    setIntervalType(
      setInterval(async () => {
        handlePercentage();
      }, 1000)
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (!(parseInt(positionVideoFinish + 1) === parseInt(numberVideo))) {
      setPercentage(percentageOld);
      return;
    }

    Socket.emit("update", {
      id: returnSubscribe.id,
      last_class_finish: positionVideoFinish,
      class_stop: numberVideo,
      percentage: percentage,
      playlistLength: playlistVideos.length,
    });
    if (percentage > percentageOld && percentage - percentageOld <= 3) {
      setPercentageOld(percentage);
    } else {
      setPercentage(percentageOld);
    }

    if (percentage === 100 || percentage === 99) {
      setPositionVideoFinish(numberVideo);

      if (percentage === 100) {
        setVideoUrl(playlistVideos[numberVideo + 1].contentDetails.videoId);
      }

      setPercentage(0);
      setPercentageOld(0);
    }
  }, [percentage]);

  async function fetchPlaylistData() {
    const memberId = await getData("memberId");
    let { data } = await api.post(`/findSubscribeCourse`, {
      user_id: memberId,
      course_id: id,
    });
    setReturnSubscribe(data.response);
    setPositionVideoFinish(data.response.last_class_finish);
    setPercentageOld(data.response.percentage - 1);
    setPercentage(data.response.percentage);
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${
        data.response.course.url.split("list=")[1]
      }&maxResults=50&part=snippet%2CcontentDetails&key=${data.youtube_api_key}`
    );

    //caso maior que 50 pegar a propriedade nextPageToken
    // const response = await fetch(
    //   `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${pageToken}&playlistId=${playlist}&maxResults=150&part=snippet%2CcontentDetails&key=AIzaSyAvZfTEahB4Y5kde4xmqeP2NwFpOUiXMtI`
    // );
    const json = await response.json();
    let items = json.items;
    setPlaylistVideos(items);

    setNumberVideo(data.response.class_stop);
    setVideoUrl(
      items[
        data.response.last_class_finish + 1 === items.length
          ? data.response.last_class_finish
          : data.response.last_class_finish + 1
      ].contentDetails.videoId
    );

    // let percentageTotal =
    //   data.response.last_class_finish + 1 === 0
    //     ? 0
    //     : ((data.response.last_class_finish + 1) / items.length) * 100;
    // setPercentageTotal(parseInt(percentageTotal));
    if (data.response.percentage > 0) {
      confirmAlert({
        title: "Video já iniciado",
        message: "Deseja Continuar de onde parou?",
        buttons: [
          {
            label: "Sim",
            onClick: async () => {
              const duration =
                await playerRef.current.internalPlayer.getDuration();
              setStartVideo((data.response.percentage / 100) * duration - 15);
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    }
  }

  async function handlePercentage() {
    if (playerRef.current) {
      const elapsed_sec =
        await playerRef.current.internalPlayer.getCurrentTime();
      const duration = await playerRef.current.internalPlayer.getDuration();

      if (duration > 0) {
        let newPercentage = parseInt((elapsed_sec / duration) * 100);

        setPercentage(newPercentage);
      }
    }
  }
  async function selectVideo(idVideo) {
    let number = playlistVideos.indexOf(
      playlistVideos.find((p) => p.contentDetails.videoId === idVideo)
    );
    if (positionVideoFinish + 1 >= number) {
      setIntervalType(null);
      setVideoUrl(idVideo);
      setNumberVideo(number);
      // let percentageTotal =
      //   positionVideoFinish + 1 === 0
      //     ? 0
      //     : ((positionVideoFinish + 1) / playlistVideos.length) * 100;
      // setPercentageTotal(parseInt(percentageTotal));
    }
  }

  return (
    <Container>
      <Menu />
      <div className="content">
        <h1>Curso: Kidscipulos</h1>
        <div className="wrappedVideo">
          <div className="video">
            <YouTube
              videoId={videoUrl}
              opts={{
                playerVars: {
                  autoplay: 1,
                  start: startVideo,
                  modestbranding: 1,
                },
              }}
              ref={playerRef}
              id="youtubePlayer"
            />
          </div>
          <div className="playlist">
            {playlistVideos.map((v, index) => (
              <div
                key={v.id}
                onClick={() => selectVideo(v.contentDetails.videoId)}
                className={
                  videoUrl !== v.contentDetails.videoId
                    ? "videoPlaylist"
                    : "videoPlaylist selected"
                }
              >
                <div className="groupDescriptionVideo">
                  <img src={`${v.snippet.thumbnails.default.url}`} alt="" />
                  <p>{v.snippet.title}</p>
                </div>

                {index <= positionVideoFinish + 1 && (
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    value={positionVideoFinish >= index ? 100 : percentage}
                  />
                )}
                {positionVideoFinish + 1 < index && (
                  <div className="blockVideo">
                    <Lock />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* <div className="progress">
          Progresso:{" "}
          <LinearProgress variant="determinate" value={percentageTotal} />{" "}
          {percentageTotal}%
        </div> */}
      </div>

      <Footer></Footer>
    </Container>
  );
}

export default PlayCourse;
