import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #94908f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    width: 200px;
  }

  .dropdown {
    position: relative;

    .profile {
      border-radius: 50%;
      width: 80px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      left: -80px;
      border-radius: 8px;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 99;
      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
          background-color: #ddd;
        }
      }
    }
    &:hover {
      .dropdown-content {
        display: block;
        z-index: 99;
        &::after {
          content: "";
          background-color: #f1f1f1;
          width: 45px;
          height: 45px;
          transform: rotate(45deg);
          position: absolute;
          right: 16px;
          top: calc(100% - 70px);
          z-index: -10;
        }
      }
    }
  }
`;
