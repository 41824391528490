import Footer from "../../components/Footer";
import ContentCourses from "./components/ContentCourses";
import Menu from "./components/Header";
import { Container } from "./styles";

function Courses() {
  return (
    <Container>
      <Menu />
      <ContentCourses />
      <Footer />
    </Container>
  );
}

export default Courses;
