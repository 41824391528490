import { LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";

import { Container, FormContainer, GroupButtons, RadioGroup } from "./styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PT from "date-fns/locale/pt-BR";
import { Button, TextField } from "@mui/material";
import { getData } from "../../util/localStorage";
import api from "../../services/api";
import MaskCep from "../../util/Validators/MaskCep";
import axios from "axios";
import { RemoveMask } from "../../util/Validators/RemoveMask";
import { MaskCelNumber } from "../../util/Validators/MaskPhone";
import { toast } from "react-toastify";
import { Save } from "@mui/icons-material";
const initialUser = {
  name: "",
  street: "",
  cep: "",
  number: "",
  neighborhood: "",
  complement: "",
  city: "",
  state: "",
  email: "",
  phone: "",
  date_of_birth: "",
  baptism_date: "",
  gender: "",
};
function Profile() {
  const [user, setUser] = useState(initialUser);
  const [photoUrl, setPhotoUrl] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    try {
      setLoading(true);
      const { data } = await api.get(`/users`);

      setUser(data);

      setUser((user) => ({
        ...user,
        date_of_birth: user.date_of_birth
          ? user.date_of_birth.toString().split("T")[0]
          : null,
        baptism_date: user.baptism_date
          ? user.baptism_date.toString().split("T")[0]
          : null,
      }));

      const photoUrl = await getData("photo");

      setPhotoUrl(photoUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const onChange = (key, value) => {
    if (typeof value === "string" || value instanceof String) {
      return setUser((user) => ({ ...user, [key]: value }));
    }
    setUser((user) => ({ ...user, [key]: value }));
  };
  async function returnCep() {
    try {
      if (MaskCep(user.cep).length === 9) {
        const { data } = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${user.cep}`
        );
        setUser((user) => ({
          ...user,
          street: data.street ? data.street.toUpperCase() : user.street,
          city: data.city ? data.city.toUpperCase() : user.city,
          state: data.state ? data.state.toUpperCase() : user.state,
          neighborhood: data.neighborhood
            ? data.neighborhood.toUpperCase()
            : user.neighborhood,
        }));
      }
      return;
    } catch (error) {}
  }

  async function handleUpdate() {
    try {
      let result = await api.put(`/users`, {
        name: user.name,
        street: user.street,
        cep: user.cep,
        number: user.number,
        neighborhood: user.neighborhood,
        complement: user.complement,
        city: user.city,
        state: user.state,
        email: user.email,
        phone: user.phone,
        date_of_birth: user.date_of_birth,
        baptism_date: user.baptism_date,
        gender: user.gender,
      });
      if (result.data) {
        toast.success("Membro atualizado com sucesso");
      } else {
        throw result;
      }
    } catch (error) {
      if (error.request.response === '{"error":"User already exists"}') {
        return toast.error("Já existe um usuário com este número de telefone");
      }
      return toast.error("Erro ao Atualizar dados do perfil");
    }
  }
  return (
    <Container>
      {!loading && (
        <div className="wrappedContent">
          <img
            class="profile"
            src={
              photoUrl
                ? photoUrl
                : "https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png"
            }
            alt="avatar"
          />
          <FormContainer>
            <form>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={PT}>
                <>
                  <div className="row">
                    <TextField
                      id="standard-basic"
                      label="Nome"
                      variant="standard"
                      onChange={(e) => onChange("name", e.target.value)}
                      value={user.name}
                      fullWidth
                    />
                  </div>
                  {user.email && (
                    <div className="row">
                      <TextField
                        id="standard-basic"
                        label="E-mail"
                        variant="standard"
                        onChange={(e) => onChange("email", e.target.value)}
                        value={user.email}
                        fullWidth
                      />
                    </div>
                  )}

                  <div className="row">
                    <TextField
                      id="standard-basic"
                      label="Cep"
                      variant="standard"
                      style={{ width: "50%" }}
                      inputProps={{ maxLength: 9 }}
                      onBlur={() => {
                        returnCep();
                      }}
                      onChange={(e) =>
                        onChange("cep", RemoveMask(e.target.value))
                      }
                      value={MaskCep(user.cep)}
                      error={user.cep === ""}
                    />
                    <TextField
                      id="standard-basic"
                      label="Telefone Celular"
                      variant="standard"
                      style={{ width: "50%" }}
                      inputProps={{ maxLength: 15 }}
                      onChange={(e) => onChange("phone", e.target.value)}
                      value={MaskCelNumber(user.phone)}
                    />
                  </div>
                  <div className="row">
                    <TextField
                      id="standard-basic"
                      label="Rua"
                      variant="standard"
                      onChange={(e) => onChange("street", e.target.value)}
                      value={user.street}
                      style={{ width: "33%" }}
                      fullWidth
                    />
                    <TextField
                      id="standard-basic"
                      label="Bairro"
                      variant="standard"
                      style={{ width: "33%" }}
                      onChange={(e) => onChange("neighborhood", e.target.value)}
                      value={user.neighborhood}
                      fullWidth
                    />
                    <TextField
                      id="standard-basic"
                      label="Número"
                      variant="standard"
                      style={{ width: "33%" }}
                      type="number"
                      onChange={(e) => onChange("number", e.target.value)}
                      value={user.number}
                      fullWidth
                    />
                  </div>
                  <div className="row">
                    <TextField
                      id="standard-basic"
                      label="Estado"
                      variant="standard"
                      onChange={(e) => onChange("state", e.target.value)}
                      value={user.state}
                      style={{ width: "33%" }}
                      fullWidth
                    />
                    <TextField
                      id="standard-basic"
                      label="Data de Nascimento"
                      variant="standard"
                      style={{ width: "33%" }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      onChange={(e) =>
                        onChange("date_of_birth", e.target.value)
                      }
                      value={user.date_of_birth}
                    />
                    <TextField
                      id="standard-basic"
                      label="Data de Batismo"
                      variant="standard"
                      style={{ width: "33%" }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      onChange={(e) => onChange("baptism_date", e.target.value)}
                      value={user.baptism_date}
                    />
                  </div>
                  <div className="row">
                    <RadioGroup
                      onChange={(v) =>
                        onChange("gender", v.target.defaultValue)
                      }
                    >
                      <h3>Gênero:</h3>
                      <div className="input">
                        <input
                          type="radio"
                          value="M"
                          name="gender"
                          defaultChecked={user.gender === "M" ? true : false}
                        />
                        Masculino
                      </div>
                      <div className="input">
                        <input
                          type="radio"
                          value="F"
                          name="gender"
                          defaultChecked={user.gender === "F" ? true : false}
                        />
                        Feminino
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="row">
                    <GroupButtons>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Save />}
                        loadingPosition="start"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          gap: 15,
                        }}
                        onClick={() => {
                          handleUpdate();
                        }}
                      >
                        Salvar
                      </Button>
                    </GroupButtons>
                  </div>
                </>
              </LocalizationProvider>
            </form>
          </FormContainer>
        </div>
      )}
    </Container>
  );
}

export default Profile;
