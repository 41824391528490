import React from "react";

import { Container } from "./styles";

function Maintenance() {
  return (
    <Container>
      <h1>Site em manutenção!</h1>
    </Container>
  );
}

export default Maintenance;
