import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #002a40;
  padding-top: 80px;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 5rem;
      color: #fff;
    }
    .wrapper-albuns {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      gap: 20px;
      flex-wrap: wrap;
      .album {
        flex: 1 1 300px;
        max-width: 300px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
        background-color: #000;
        transition: 0.5s;
        img {
          width: 100%;
          height: 87%;
          object-fit: cover;
          border-radius: 16px 16px 0 0;
        }
        h3 {
          color: #fff;
          font-size: 2rem;
        }
        :hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
  }
`;
