import React from "react";
import { Container } from "./styles";
import { Parallax } from "react-parallax";
import Image from "../../assets/oque_cremos.jpg";

function Believer() {
  return (
    <Container>
      <div className="wrapper">
        <Parallax
          bgImage={Image}
          strength={800}
          style={{ width: "100%", borderRadius: 16 }}
        >
          <div style={{ height: "250px" }} />
        </Parallax>

        <section className="intro">
          <h3 className="topo">QUEM SOMOS?</h3>
          <h3>Uma Igreja de Discípulos e não frequentadores.</h3>
        </section>

        <section className="text-group">
          <h3>Cremos:</h3>
          <p>
            I - Em um só Deus eterno e indivisível, manifesto como Pai, na
            criação, como Filho na regeneração e como Espírito Santo na
            Consolação conforme Deut.6:4; João1:1-2,12,14,18; João 14:6-11;
            Isaías 44:6 e 48:12; Apocalipse 1:7, 2:8 e 22:13; Efésios 4:4-6; 1º
            Coríntios 3:17-18; Romanos 9:5;
          </p>
          <p>
            II - No Batismo nas águas por imersão em nome de Jesus Cristo,
            conforme Atos 2:38, 8:12,16, 10:48, 19:5, 22:16 e outras
            referências;
          </p>
          <p>
            III - Na Bíblia Sagrada, como única regra infalível de fé normativa
            para a vida e o caráter cristão;
          </p>
          <p>
            IV - Na liturgia da Comunidade, em suas diversas formas e práticas,
            suas doutrinas, costumes e captação de recursos para sua manutenção;
          </p>
          <p>
            V - No Batismo com o Espírito Santo, na manifestação dos dons do
            Espírito, conforme escrito na Bíblia em I Coríntios capítulo 12;
          </p>
          <p>
            VI - Na graça de Deus e na vida de Santidade, conforme ensinado
            amplamente na Bíblia Sagrada;
          </p>
          <p>
            VI - No arrebatamento dos santos, na segunda vinda do Senhor Jesus
            Cristo, no julgamento final conforme I Tessalonicenses 3:17, e
            Mateus 25:41.
          </p>
        </section>

        <section className="text-group">
          <h3>Prioridade ao ensino bíblico.</h3>
          <p>
            É a base de transformação do indivíduo e da congregação. 2 Tm
            3:14-17; Tg 1:23-24; Hb 4:12
          </p>
        </section>

        <section className="text-group">
          <h3>Pluralidade de liderança.</h3>
          <p>
            Composta por homens íntegros e reconhecidos pela igreja. Ex 18:
            13-27; 1 Tm 3; Tt 1
          </p>
        </section>

        <section className="text-group">
          <h3>Evangelização a partir do estilo de vida.</h3>
          <p>
            Porque Deus se importa com os perdidos, nós também nos importamos.
            Relacionamentos íntegros devem ser estabelecidos. Mt 28:19, 20; At
            11:19-2
          </p>
        </section>

        <section className="text-group">
          <h3>Planejamento baseado nos propósitos.</h3>
          <p>
            Os alvos devem ser funcionais, realistas e objetivos. A igreja deve
            ser culturalmente relevante, enquanto permanece doutrinariamente
            pura. 1 Co 9:19-23; 1 Co 16:5-12
          </p>
        </section>

        <section className="text-group">
          <h3>Na sua reprodução, a igreja deve desenvolver o discipulado.</h3>
          <p>
            A reprodução é conforme à imagem de Cristo. O discipulado não é uma
            opção, mas uma ordem (“fazei”). Devemos nos envolver para tornar um
            indivíduo em um discípulo frutífero de Jesus.
          </p>
        </section>

        <section className="text-group">
          <h3>Ativos no ministério – no lugar certo, pelas razões certas.</h3>
          <p>
            A rede de ministérios auxilia os membros a identificar seu perfil de
            servo e os adequar em um ministério específico. 1 Pe 4:10,12; Rm
            12:3–8
          </p>
        </section>

        <section className="text-group">
          <h3>O amor deve permear o cuidado de uns pelos outros.</h3>
          <p>
            Devemos buscar os que estão lá fora... Não podemos nos descuidar dos
            que estão aqui dentro para promover a edificação mútua. Cl 3:12-17;
            Gl 6:1,2
          </p>
        </section>

        <section className="text-group">
          <h3>A igreja deve dar ênfase ao fortalecimento das famílias.</h3>
          <p>
            Tanto o A.T. como o N.T. ensinam que, prioritariamente, os pais
            devem transmitir o ensino da Palavra de Deus aos filhos; os cônjuges
            devem amar e respeitar-se mutuamente. Dt 6:4-9; Ef 5:18-6:4
          </p>
        </section>

        <section className="text-group">
          <h3>Busca de excelência implica em mudanças e transformações.</h3>
          <p>
            Buscar excelência traz honra a Deus e reflete o seu caráter.
            Crescimento pede avaliação, planejamento e trabalho árduo. Mt
            23:13-36; Cl 3:17
          </p>
        </section>

        <section className="text-group">
          <h3>Fé exercitada e desenvolvida na dinâmica da vida.</h3>
          <p>
            O povo precisa ter sua fé desafiada para crer naquilo que Deus pode
            fazer, da maneira como Ele quer fazer, no tempo que Ele determinou
            para fazer. 2 Co 8 e 9; Rm 1:16,17
          </p>
        </section>

        <section className="text-group">
          <h3>Mordomia e consagração ao Senhor.</h3>
          <p>
            A total consagração e submissão ao Senhor são características do
            discípulo frutífero de Jesus. 2 Co 9:7-13; Cl 3:17
          </p>
        </section>

        <section className="text-group">
          <h3>Adoração e oração.</h3>
          <p>
            Somos desafiados a glorificar a Deus por aquilo que Ele é,
            louvá-Lo por aquilo que Ele fez e celebrar pelo que Ele tem feito.
            Rm 14:17; Sl 145; Sl 150
          </p>
        </section>

        <section className="text-group">
          <h3>A igreja se desenvolve através das Células.</h3>
          <p>
            As Células são a Igreja de Cristo nos lares. Elas não são apenas
            grupos de estudo, mas comunidades cristãs de base. Jesus é o centro
            do encontro.
          </p>
          <p>
            Cada membro ministra usando seus dons, e cada um amadurece em
            Cristo, através de relacionamentos abertos e honestos.
          </p>
        </section>

        <section className="text-group">
          <h3>Evangelismo por amizade.</h3>
          <p>
            Todos podem se envolver no evangelismo por amizade, criando uma Rede
            de Comunicação Informal (RCI) com familiares, colegas de trabalho,
            amigos e vizinhos. O foco é compartilhar a vida de Cristo
            intencionalmente. Atos 5:42
          </p>
        </section>
      </div>
    </Container>
  );
}

export default Believer;
