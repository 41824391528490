/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import Logo from "../../assets/logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaSpotify,
  FaMicrophoneAlt,
} from "react-icons/fa";
import { AiOutlineDown } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose, CgLogIn } from "react-icons/cg";
import { Link } from "react-router-dom";
import { getData, deleteData } from "../../util/localStorage";
import history from "../../services/history";
import Worship from "../../assets/worship.png";
import { useUser } from "../../Contexts/UserContext";
function Home() {
  const [toggle, setToggle] = useState(false);
  const [logado, setLogado] = useState(false);
  const [user, setUser] = useState(false);
  const [photoUrl, setPhotoUrl] = useState();
  const { user:userContext } = useUser(); // Obtenha o setUser do contexto

  useEffect(() => {
    getInfo();
  }, [userContext]);
  
  useEffect(() => {
    getInfo();
  }, []);
  async function getInfo() {
    const user = await getData("user");
    const photoUrl = await getData("photo");

    setPhotoUrl(photoUrl);
    if (user) {
      setUser(user);
      if (user.token) {
        setLogado(true);
      }
    } else {
      setLogado(false);
    }
  }

  async function handleToggle() {
    setToggle(!toggle);
  }
  return (
    <Container>
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
      <section className={"menu"}>
        <nav>
          <ul>
            <li>
              CONHEÇA <AiOutlineDown size={14} />
              <ul>
                <li>
                  <Link to="/Believer">A PLENITUDE</Link>
                </li>
                <li>
                  <a href="https://www.instagram.com/pibmirandopolis/">
                    EQUIPE
                  </a>
                </li>
                <li>
                  <Link to="/Local">COMO CHEGAR</Link>
                </li>
                <li>
                  <Link to="/Offers">DIZIMOS E OFERTAS</Link>
                </li>
              </ul>
            </li>
            <li>
              RECURSOS <AiOutlineDown size={14} />
              <ul>
                <li>
                  <a
                    href="https://castbox.fm/channel/PIBCast-%7C-Plenitude-Igreja-Batista-id2494870?country=br"
                    target="_blank"
                  >
                    PODCAST
                  </a>
                </li>
                <li>
                  <Link to="/Celulas">CÉLULAS</Link>
                </li>
                <li>
                  <Link to="/Cursos">CURSOS</Link>
                </li>

                <li>
                  <Link to="/Galeria">GALERIA</Link>
                </li>
              </ul>
            </li>
            <li>
              AGENDA <AiOutlineDown size={14} />
              <ul>
                <li>
                  <a href="https://www.instagram.com/pibmirandopolis/">
                    PROGRAMAÇÃO
                  </a>
                </li>
                <li>
                  <Link to="/Events">EVENTOS</Link>
                </li>
              </ul>
            </li>
            {!logado && (
              <li>
                <Link to="/Login">
                  <CgLogIn size={25} />
                  <p>ENTRAR</p>
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <div className="icons">
          <a
            href="https://www.facebook.com/pibmirandopolissp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href="https://www.instagram.com/pibmirandopolis/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://www.youtube.com/pibmirandopolis?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={20} />
          </a>
          <a
            href="https://open.spotify.com/user/08iijmltl2bw9729dy746scrg?si=HqIdmkceSteWycUxqypkHg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSpotify size={20} />
          </a>

          <a
            href="https://castbox.fm/channel/PIBCast-%7C-Plenitude-Igreja-Batista-id2494870?country=br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaMicrophoneAlt size={20} />
          </a>
        </div>
      </section>
      {/* menu responsivo */}
      <div className="menu-responsive" logado={`${logado}`}>
        {logado && (
          <div className="profile">
            <p>Seja Bem vindo</p>
            <p>{user.user.name}</p>
          </div>
        )}
        <GiHamburgerMenu
          onClick={() => handleToggle()}
          size={24}
          color="white"
        />
      </div>
      <div className={!toggle ? "menu-section" : "menu-section-on"}>
        <div className="header">
          <img src={Worship} alt="fundo do drawer" />
          <img
            id="avatar"
            src={
              photoUrl
                ? photoUrl
                : "https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png"
            }
            alt="avatar"
          />
          <p>{user ? user.user.name : ""}</p>
          <CgClose onClick={() => handleToggle()} size={36} color="white" />
        </div>

        <nav>
          <ul>
            <li>
              <div className="op">
                CONHEÇA <AiOutlineDown size={18} color="#fff" />
              </div>
              <ul>
                <li>
                  <Link to="/Believer" onClick={() => handleToggle()}>
                    A PLENITUDE
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/pibmirandopolis/"
                    target="_blank"
                  >
                    EQUIPE
                  </a>
                </li>
                <li>
                  <Link to="/Local">COMO CHEGAR</Link>
                </li>
                <li>
                  <Link to="/Offers" onClick={() => handleToggle()}>
                    DIZIMOS E OFERTAS
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div className="op">
                RECURSOS <AiOutlineDown size={18} color="#fff" />
              </div>
              <ul>
                <li>
                  <a
                    href="https://castbox.fm/channel/PIBCast-%7C-Plenitude-Igreja-Batista-id2494870?country=br"
                    target="_blank"
                  >
                    PODCAST
                  </a>
                </li>
                <li>
                  <Link to="/Celulas">CÉLULAS</Link>
                </li>
                <li>
                  <Link to="/Cursos">CURSOS</Link>
                </li>

                <li>
                  <Link to="/Galeria">GALERIA</Link>
                </li>
              </ul>
            </li>
            <li>
              <div className="op">
                AGENDA <AiOutlineDown size={18} color="#fff" />
              </div>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/pibmirandopolis/"
                    target="_blank"
                  >
                    PROGRAMAÇÃO
                  </a>
                </li>
                <li>
                  <Link to="/Events" onClick={() => handleToggle()}>
                    EVENTOS
                  </Link>
                </li>
              </ul>
            </li>
            {logado && (
              <li>
                {" "}
                <Link to="/Ticket" onClick={() => handleToggle()}>
                  <p>INGRESSOS</p>
                </Link>
              </li>
            )}
            {logado && user.user.provider && (
              <li>
                <Link to="/Members">CENTRAL</Link>
              </li>
            )}

            {logado && (
              <li>
                <Link to="/profile">PERFIL</Link>
              </li>
            )}

            {logado && (
              <li
                onClick={() => {
                  deleteData("user");
                  deleteData("photo");
                  deleteData("memberId");
                  setPhotoUrl(null);
                  setUser(null);
                  setLogado(false);
                  handleToggle();
                  history.push("/");
                }}
              >
                SAIR
              </li>
            )}
            {!logado && (
              <li>
                <Link to="/Login" onClick={() => handleToggle()}>
                  <p>ENTRAR</p>
                  <CgLogIn size={25} />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </Container>
  );
}

export default Home;
