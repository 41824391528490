import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "./styles";
import api from "../../services/api";
import { Parallax } from "react-parallax";
import { ptBR } from "date-fns/locale";
import { parseISO, format } from "date-fns";
import { toast } from "react-toastify";
import { getData } from "../../util/localStorage";

function InfoEvents() {
  const { id } = useParams();
  const [events, setEvents] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro

  useEffect(() => {
    getInfo();
  }, [id]);

  async function getInfo() {
    setLoading(true);
    try {
      const response = await api.get(`/events/${id}`);
      setEvents(response.data);
    } catch (error) {
      setError("Erro ao carregar dados do evento");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleInscription() {
    const user = await getData("user");
    if (!user) {
      return toast.error("É necessário estar logado para realizar esta ação");
    }
    if (!checked) {
      return toast.error(
        "É necessário aceitar os termos para se cadastrar no evento"
      );
    }
    try {
      const result = await api.post("/confirm", {
        event_id: id,
        agree_terms: checked,
      });

      if (result.data.limit_exceeded) {
        toast.error("Limite de inscrições excedido, não é possível se inscrever.");
      } else {
        toast.success("Sua inscrição neste evento foi confirmada.");
      }
    } catch (error) {
      toast.error(
        "Você já se encontra cadastrado neste evento. Vá para a tela de ingressos."
      );
    }
  }

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container>
      <div className="wrapper">
        {events && (
          <>
            <Parallax
              bgImage={events.avatar_event.url}
              bgImageAlt="evento"
              bgImageStyle={{
                maxHeight: 500,
                width: "100%",
              }}
              strength={800}
              style={{ width: "80%", borderRadius: 16 }}
            >
              <div style={{ height: "250px" }} />
            </Parallax>
            <h1>{events.name}</h1>
            <div className="content">
              <p>{events.description}</p>
              <div className="info">
                <p>
                  Data:{" "}
                  {format(
                    parseISO(events.date),
                    "'dia' dd 'de' MMMM', ás' H:mm'h'",
                    {
                      locale: ptBR,
                    }
                  )}
                </p>
                <p>Local: {events.locale}</p>
              </div>
              {events.need_confirm_presence && (
                <div className="inscription">
                  <div className="text">
                    Taxa de Inscrição:
                    <span>
                      {events.value === 0 && events.free_entry === false && "1kg de alimento"}
                      {events.value === 0 && events.free_entry === true && "Entrada Gratuita"}
                      {events.value > 0 && `R$ ${events.value.toFixed(2).replace(".", ",")}`}
                    </span>
                  </div>

                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="AcceptTerms"
                      name="AcceptTerms"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      aria-label="Aceitar os termos"
                    />
                    <label htmlFor="AcceptTerms">Concordo e estou ciente</label>
                  </div>

                  <button onClick={handleInscription}>Inscreva-se</button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default InfoEvents;
