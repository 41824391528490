import styled from "styled-components";

export const Container = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h4 {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;
export const Label = styled.div`
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
`;
export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  background: #eee;
  background-size: cover;
`;
export const imgProfile = styled.div`
  max-width: 100%;
  max-height: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  background: #eee;
  background-size: cover;
  img {
    border-radius: 50%;
  }
`;
export const Input = styled.input`
  position: absolute;
  height: 100%;
  max-width: 100%;
  opacity: 0;
  top: 0;
`;
export const AddImage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WebcamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
