import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e5f8f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    padding: 20px;
    min-height: 600px;
  }
`;

export const Description = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
  img {
    width: 200px;
    height: 200px;
  }
  .contentDescription {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    p {
      text-align: justify;
      font-size:1.6rem;
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    button {
      height: 40px;
      width: 150px ;
      border-radius: 16px;
      padding: 5px;
      color:white;
      font-weight:600;
      &:hover{
        cursor:pointer;
        transform:scale(1.03);
      }
    }
    .inscription {
      background-color: green;
    }
    .registered{
      background-color:gray;
    }
    .view{
      background-color: red ;
    }}
  }
`;

export const WrappedCarousel = styled.div`
  padding: 20px;
  background: #419be0;
  max-height: 390px;
`;
