import { useEffect, useState } from "react";
import { BiChurch } from "react-icons/bi";
import { MdClass,MdEvent } from "react-icons/md";
import { ImArrowLeft2 } from "react-icons/im";

import { Link } from "react-router-dom";
import { Container } from "./styles";
import { getData } from "../../../util/localStorage";

function Central({ children }) {
  const [menuSelected, setMenuSelected] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.pathname);

    getInfo();
  }, []);

  useEffect(() => {
    if (path === "/Members") {
      setMenuSelected(1);
    }
    if (path === "/CadastroCursos") {
      setMenuSelected(2);
    }
    if (path === "/CenterEvents") {
      setMenuSelected(3);
    }
  }, [path]);
  async function getInfo() {
    try {
      const user = await getData("user");
      setIsAdmin(user.user.provider);
    } catch (error) {}
  }
  function selected(menu) {
    setMenuSelected(menu);
  }
  return (
    <Container>
      <div className="navigation">
        <ul>
          <li
            className={menuSelected === 1 ? "list active" : "list"}
            onClick={() => selected(1)}
          >
            <Link to="/Members">
              <span className="icon">
                <BiChurch color="white" size={32} />
              </span>
              <span className="title">Membros</span>
            </Link>
          </li>
          {isAdmin && (
            <li
              className={menuSelected === 2 ? "list active" : "list"}
              onClick={() => selected(2)}
            >
              <Link to="/CadastroCursos">
                <span className="icon">
                  <MdClass color="white" size={32} />
                </span>
                <span className="title">Cursos</span>
              </Link>
            </li>
          )}
          {isAdmin && (
            <li
              className={menuSelected === 3 ? "list active" : "list"}
              onClick={() => selected(3)}
            >
              <Link to="/CenterEvents">
                <span className="icon">
                  <MdEvent color="white" size={32} />
                </span>
                <span className="title">Eventos</span>
              </Link>
            </li>
          )}

          <li
            className={menuSelected === 4 ? "list active" : "list"}
            onClick={() => selected(3)}
          >
            <Link to="/">
              <span className="icon">
                <ImArrowLeft2 color="white" size={32} />
              </span>
              <span className="title">Sair</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="content">{children}</div>
    </Container>
  );
}

export default Central;
