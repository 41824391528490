import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
`;
export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  gap: 15px;
`;
export const GridContaint = styled.div`
  width: 100%;
  height: 70%;
  overflow: auto;
  background-color: white;

  .MuiDataGrid-columnHeader{
    font-weight: 600 !important;
    color: black;
    font-size: 1rem;
  }
  .MuiDataGrid-columnHeaders {
    background-color: black;
    color: white;
  }
  #mui-2 {
    font-size: 1rem !important;
    color: black;
  }
  .MuiTablePagination-actions {
    button {
      svg {
        fill: black;
      }
    }
  }
`;
export const ModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ContentModalView = styled.div`
  min-width: 50%;
  overflow-y: auto;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  min-height: 60%;
  max-height: 100%;
  .group {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .playlist {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      width: 20%;
      height: 100%;
      .thumbnail {
        width: 100%;
      }
      .videos {
        overflow-y: auto;
        max-height: 300px;
      }
    }
  }
`;
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    padding: 10px 15px;
    .row {
      display: flex;
      gap: 20px;
      width: 100%;
      align-items: center;

      #free-solo-demo-label {
        font-size: 1.3rem;
        font-weight: 600 !important;
      }
      .MuiAutocomplete-root {
        padding-top: 0.5px;
      }
      #accordion {
        width: 100%;
        #panel1a-header {
          width: 100%;
          padding: 0 10px;
        }
        #panel1a-content {
          padding: 0 0 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        #standard-textarea-label {
          font-size: 1.5rem;
        }

        svg {
          fill: black;
        }
      }
    }
  }
`;
