import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e5f8f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    padding: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .wrappedVideo {
      display: flex;
      gap: 30px;

      .video {
        width: 70%;
        max-height: 400px;
        div {
          width: 100%;
          height: 100%;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .playlist {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 400px;
        overflow-y: auto;
        padding-right: 5px;

        .videoPlaylist {
          width: 100%;
          height: 60px;
          position: relative;
          .groupDescriptionVideo {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            img {
              max-width: 120px;
              max-height: 100%;
            }
            p {
              max-width: 230px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
          &:hover {
            cursor: pointer;
            background-color: #ddd;
          }
        }
        .videoPlaylist.selected {
          background-color: #ddd;
        }
        .blockVideo {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 99;
        }
      }
    }
    .progress {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 5px;
      span {
        width: 100%;
      }
    }
  }
`;
